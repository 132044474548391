import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./AllTimeSeriesListStyles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const AllTimeSeriesList = ({ subjectList }) => {
  const classes = useStyles();
  let history = useHistory();

  const generateTableData = (list) => {
    let gatheredData = [];
    list
      .filter((subject) => subject.timeseriesfiles.length > 0)
      .map(({ first_name, timeseriesfiles, sessions }) => {
        for (const tsf of timeseriesfiles) {
          const sessionId = parseInt(tsf.session.split("/").slice(-2, -1)[0]);
          const tsfId = parseInt(tsf.url.split("/").slice(-2, -1)[0]);
          gatheredData.push({
            name: first_name,
            sessionId: sessionId,
            link: tsf.gcs_link,
            date: sessions.find((session) => session.id === sessionId).date,
            tsfURL: tsf.url,
            tsfId: tsfId,
          });
        }
      });
    return gatheredData;
  };

  const changeBackgroundHover = (e) => {
    e.target.style.background = "#D3D3D3";
  };
  const changeBackgroundLeave = (e) => {
    e.target.style.background = "white";
  };

  const tableData = generateTableData(subjectList);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            Subject
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            Session Date
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            Timeseries File
          </Typography>
        </Grid>
        {tableData.map(({ name, link, date, tsfId }) => (
          <>
            <Grid item xs={4}>
              {name}
            </Grid>
            <Grid item xs={4}>
              {date}
            </Grid>
            <Grid
              item
              xs={4}
              onMouseOver={changeBackgroundHover}
              onMouseLeave={changeBackgroundLeave}
              onClick={() => history.push(`/tsa/${tsfId}/`)}
            >
              {/* TODO linking to new page works.  Pass tsfURL to new page and then append /raw to end and load that data into tsv */}
              {link}
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
};

AllTimeSeriesList.propTypes = {};

export default AllTimeSeriesList;
