import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StatusWithToolTip from "./StatusWithToolTip";
import { TextField } from "formik-material-ui";
import DateFnsUtils from "@date-io/date-fns";

const demographicStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    maxWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Demographic = ({ updateFormData, isButtonLoading, formValues }) => {
  const classes = demographicStyles();
  const [expanded, setExpanded] = useState(false);
  let completed = formValues.Demographic_Completed;

  const initialValues = {
    StudyID: null,
    Date: null,
    VisitNumber: null,
    Sex: "",
    Age: null,
    Height: null,
    Weight: null,
    MedicationList: "",
  };

  const ValidationSchema = Yup.object().shape({
    // Demographic
    Height: Yup.number()
      .required()
      .min(0, "Must be greater than 0")
      .max(240, "Must be less than 240")
      .nullable(), // cm -> 8ft
    Weight: Yup.number()
      .min(0, "Must be greater than 0")
      .max(225, "Must be less than 225")
      .nullable(), // kg -> 500lbs
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <StatusWithToolTip completed={completed} />
                    <Typography className={classes.heading}>
                      Demographic
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ paddingLeft: "10px" }}
                    >
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            disabled={true}
                            name="StudyID"
                            label="SubjectID"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            disabled={true}
                            name="Date"
                            label="Date"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            name="VisitNumber"
                            label="Visit Number"
                            disabled={true}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            name="Sex"
                            label="Sex"
                            disabled={true}
                          ></Field>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            disabled={true}
                            name="Age"
                            label="Age"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            type="number"
                            name="Height"
                            helperText="Must be less than 240"
                            label="Height (cm)"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            type="number"
                            name="Weight"
                            label="Weight (kg)"
                            helperText="Must be less than 225"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            type="text"
                            multiline
                            name="MedicationList"
                            label="Medication List"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {/* <Button >Mark Complete</Button> */}
                  </>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    disabled={!(dirty && isValid)}
                    size="small"
                    onClick={() =>
                      updateFormData(
                        "completeButton",
                        completed,
                        values,
                        "Demographic_Completed"
                      )
                    }
                    style={{
                      backgroundColor:
                        !dirty || !isValid
                          ? "grey"
                          : completed
                          ? "#CD6F00"
                          : "#276700",
                      color: "#ffffff",
                    }}
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : completed ? (
                      <>Mark Incomplete</>
                    ) : (
                      <>Mark Complete</>
                    )}
                  </Button>
                  <Button
                    disabled={!(dirty && isValid)}
                    onClick={() =>
                      updateFormData("saveButton", completed, values)
                    }
                    size="small"
                    variant="outlined"
                    color="primary"
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </AccordionActions>
              </Accordion>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    </div>
  );
};
export default Demographic;
