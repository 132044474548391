import React from "react";
import PropTypes from "prop-types";
import PermissionDenied from "../../../PermissionDenied/PermissionDenied";
import { usePermission } from "../../../../hooks/permissions/usePermission";
import { MTBI_ROLE } from "../../../../roles";

const roles = [MTBI_ROLE];

const MtbiStudy = (props) => {
  let hasPermission = usePermission(roles);
  if (!hasPermission) return <PermissionDenied />;
  return (
    <div>
      <h1>MTBI Under Construction</h1>
    </div>
  );
};

export default MtbiStudy;
