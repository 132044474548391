import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  FormControlLabel,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  TextareaAutosize,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField, Switch } from "formik-material-ui";
import StatusWithToolTip from "./StatusWithToolTip";

export const mriStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    maxWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const MRI = ({ updateFormData, isButtonLoading, formValues }) => {
  const classes = mriStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [note, setNote] = React.useState(formValues.Notes_on_sequences);
  let completed = formValues.MRI_Completed;

  const initialValues = {
    Baseline_endtidal_partial_pressure_co2: null,
    End_breath_hold_endtidal_partial_pressure_co2: null,
    T1: false,
    T2: false,
    Breath_hold: false,
    ASL: false,
    DTI: false,
    Resting_state: false,
    Notes_on_sequences: "",
  };
  const ValidationSchema = Yup.object().shape({
    Baseline_endtidal_partial_pressure_co2: Yup.number().nullable(),
    End_breath_hold_endtidal_partial_pressure_co2: Yup.number().nullable(),
    // Notes_on_sequences: Yup.string().max(5, "Max 5 characters"), // do we need validation?
  });

  const handleNoteChange = (e) => {
    setNote(e.target.value);
    formValues["Notes_on_sequences"] = e.target.value;
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {console.log(values)}
            <Form>
              {" "}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <StatusWithToolTip completed={completed} />
                    <Typography className={classes.heading}>MRI</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ paddingLeft: "10px" }}
                    >
                      <Grid item xs={6}>
                        <Box margin={1}>
                          <Field
                            component={TextField}
                            style={{ width: "99%" }}
                            type="number"
                            name="Baseline_endtidal_partial_pressure_co2"
                            label="Baseline End tidal Partial Pressure CO2(mmHg)"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box margin={1}>
                          <Field
                            style={{ width: "99%" }}
                            component={TextField}
                            type="number"
                            name="End_breath_hold_endtidal_partial_pressure_co2"
                            label="End Breathhold End Tidal Partial Pressure CO2(mmHg)"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="T1"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="T1"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="T2"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="T2"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="Breath Hold"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Breath_hold"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="ASL"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="ASL"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="DTI"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="DTI"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="Resting State"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Resting_state"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "left" }}>
                        <Box margin={1}>
                          <Field
                            // component={TextField}
                            style={{ width: "100%" }}
                            // type="text"
                            as="textarea"
                            rowsMin={5}
                            rowsMax={10}
                            name="Notes_on_sequences"
                            placeholder="Notes on Sequences"
                            // value={note}
                            // onChange={handleNoteChange}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    disabled={!(dirty && isValid)}
                    size="small"
                    onClick={() =>
                      updateFormData(
                        "completeButton",
                        completed,
                        values,
                        "MRI_Completed"
                      )
                    }
                    style={{
                      backgroundColor:
                        !dirty || !isValid
                          ? "grey"
                          : completed
                          ? "#CD6F00"
                          : "#276700",
                      color: "#ffffff",
                    }}
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : completed ? (
                      <>Mark Incomplete</>
                    ) : (
                      <>Mark Complete</>
                    )}
                  </Button>
                  <Button
                    disabled={!(dirty && isValid)}
                    onClick={() =>
                      updateFormData(
                        "saveButton",
                        completed,
                        values,
                        "MRI_Completed"
                      )
                    }
                    size="small"
                    variant="outlined"
                    color="primary"
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </AccordionActions>
              </Accordion>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    </div>
  );
};
export default MRI;
