import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { TextField } from "formik-material-ui";
import StatusWithToolTip from "./StatusWithToolTip";

const mocaStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    maxWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Moca = ({ updateFormData, isButtonLoading, formValues }) => {
  //               "Total_score_out_of_thirty_moca": null,
  const classes = mocaStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [note, setNote] = React.useState(formValues.Notes_on_sequences);
  let completed = formValues.Moca_Completed;

  const initialValues = {
    Trail_making: 0,
    Cube: 0,
    Clock_contour: 0,
    Clock_hands: 0,
    Clock_numbers: 0,
    Naming_camel: 0,
    Naming_lion: 0,
    Naming_rhino: 0,
    Digits_forward: 0,
    Digits_reverse: 0,
    Letters: 0,
    Serial_subtraction: 0,
    Language: 0,
    Words_starting_with_F: 0,
    Abstraction: 0,
    Delayed_recall: 0,
    Orientation: 0,
    Num_of_words: null,
    Education_level: "greater_than_12_years",
  };

  const ValidationSchema = Yup.object().shape({
    Trail_making: Yup.number()
      .integer()
      .min(0, "Must be <= 1")
      .max(1, "Must be <= 1")
      .nullable(),
    Cube: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Clock_contour: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Clock_hands: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Clock_numbers: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Naming_camel: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Naming_lion: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Naming_rhino: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Digits_forward: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Digits_reverse: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Letters: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Serial_subtraction: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(3, "Must be <= 3")
      .nullable(),
    Language: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(2, "Must be <= 2")
      .nullable(),
    Words_starting_with_F: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(1, "Must be <= 1")
      .nullable(),
    Abstraction: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(2, "Must be <= 2")
      .nullable(),
    Delayed_recall: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(5, "Must be <= 5")
      .nullable(),
    Orientation: Yup.number()
      .integer()
      .min(0, "Must be greater than 0")
      .max(6, "Must be <= 6")
      .nullable(),
  });

  const handleNoteChange = (e) => {
    setNote(e.target.value);
    formValues["Notes_on_sequences"] = e.target.value;
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => {
          values.Total_score_out_of_thirty_moca =
            values.Trail_making +
            values.Cube +
            values.Clock_contour +
            values.Clock_hands +
            values.Clock_numbers +
            values.Naming_camel +
            values.Naming_lion +
            values.Naming_rhino +
            values.Digits_forward +
            values.Digits_reverse +
            values.Letters +
            values.Serial_subtraction +
            values.Language +
            values.Words_starting_with_F +
            values.Abstraction +
            values.Delayed_recall +
            values.Orientation;
          if (!!!values.Trail_making) {
            values.Trail_making = 0;
          }
          if (!!!values.Cube) {
            values.Cube = 0;
          }
          if (!!!values.Clock_contour) {
            values.Clock_contour = 0;
          }
          if (!!!values.Clock_hands) {
            values.Clock_hands = 0;
          }
          if (!!!values.Clock_numbers) {
            values.Clock_numbers = 0;
          }
          if (!!!values.Naming_camel) {
            values.Naming_camel = 0;
          }
          if (!!!values.Naming_lion) {
            values.Naming_lion = 0;
          }
          if (!!!values.Naming_rhino) {
            values.Naming_rhino = 0;
          }
          if (!!!values.Digits_forward) {
            values.Digits_forward = 0;
          }
          if (!!!values.Digits_reverse) {
            values.Digits_reverse = 0;
          }
          if (!!!values.Letters) {
            values.Letters = 0;
          }
          if (!!!values.Serial_subtraction) {
            values.Serial_subtraction = 0;
          }
          if (!!!values.Language) {
            values.Language = 0;
          }
          if (!!!values.Words_starting_with_F) {
            values.Words_starting_with_F = 0;
          }
          if (!!!values.Abstraction) {
            values.Abstraction = 0;
          }
          if (!!!values.Delayed_recall) {
            values.Delayed_recall = 0;
          }
          if (!!!values.Orientation) {
            values.Orientation = 0;
          }
          if (!!!values.Num_of_words) {
            values.Num_of_words = 0;
          }
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <StatusWithToolTip completed={completed} />
                      <Typography className={classes.heading}>MoCA</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ paddingLeft: "10px" }}
                      >
                        <Grid item xs={12}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Trail_making"
                                label="Trail making (/1)"
                              />
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Cube"
                                label="Cube (/1)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Clock</Typography>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Clock_contour"
                                label="Contour (/1)"
                              />
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Clock_numbers"
                                label="Numbers (/1)"
                              />
                              <Field
                                component={TextField}
                                type="number"
                                name="Clock_hands"
                                label="Hands (/1)"
                              />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Naming</Typography>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Naming_lion"
                                label="Lion (/1)"
                              />
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Naming_rhino"
                                label="Rhino (/1)"
                              />
                              <Field
                                component={TextField}
                                type="number"
                                name="Naming_camel"
                                label="Camel (/1)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Digits</Typography>
                            {/* "Digits_forward": 0, // "Digits_reverse": 0, */}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Digits_forward"
                                label="Forward (/1)"
                              />
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Digits_reverse"
                                label="Reverse (/1)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            // style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Letters"
                                label="Letters (/1)"
                              />
                              <Field
                                component={TextField}
                                style={{ marginRight: "10px" }}
                                type="number"
                                name="Serial_subtraction"
                                label="Serial Subtraction (/3)"
                              />
                              <Field
                                component={TextField}
                                type="number"
                                name="Language"
                                label="Language (/2)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Field
                                component={TextField}
                                style={{
                                  marginRight: "10px",
                                  minWidth: "250px",
                                }}
                                type="number"
                                name="Words_starting_with_F"
                                label="Words starting with F(/1)"
                              />
                              <Field
                                component={TextField}
                                type="number"
                                name="Num_of_words"
                                label="Number of words"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Abstraction"
                                label="Abstraction (/2)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Delayed_recall"
                                label="Delayed Recall (/5)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Orientation"
                                label="Orientation (/6)"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Level of Education
                            </Typography>
                            <div style={{ width: "100%" }}>
                              <Field
                                name="Education_level"
                                as="select"
                                label="Education Level"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                <option value="greater_than_12_years">
                                  {">"}12 years
                                </option>
                                <option value="less_than_or_equal_to_12_years">
                                  {"<="}12 years
                                </option>
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ marginLeft: "26px" }}>
                      <Typography variant="h6">
                        Total Score: {values.Total_score_out_of_thirty_moca}/30
                      </Typography>
                    </div>
                    <div>
                      <Button
                        disabled={!(dirty && isValid)}
                        size="small"
                        onClick={() =>
                          updateFormData(
                            "completeButton",
                            completed,
                            values,
                            "Moca_Completed"
                          )
                        }
                        style={{
                          backgroundColor:
                            !dirty || !isValid
                              ? "grey"
                              : completed
                              ? "#CD6F00"
                              : "#276700",
                          color: "#ffffff",
                          marginRight: "10px",
                        }}
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : completed ? (
                          <>Mark Incomplete</>
                        ) : (
                          <>Mark Complete</>
                        )}
                      </Button>
                      <Button
                        disabled={!(dirty && isValid)}
                        onClick={() =>
                          updateFormData(
                            "saveButton",
                            completed,
                            values,
                            "Moca_Completed"
                          )
                        }
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </div>
                  </AccordionActions>
                </Accordion>
              </Form>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    </div>
  );
};
export default Moca;
