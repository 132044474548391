import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "./SubjectEnrollmentStyles";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import AddSearchSubject from "./components/AddSearchSubject/AddSearchSubject";
import { STROKE_ROLE } from "../../../../roles";
import { usePermission } from "../../../../hooks/permissions/usePermission";
import PermissionDenied from "../../../PermissionDenied/PermissionDenied";

//  onClick={() => history.push(`/tsa/${tsfId}/`)}
const roles = [STROKE_ROLE];

const SubjectEnrollment = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  let hasPermission = usePermission(roles);
  if (!hasPermission) return <PermissionDenied />;

  return (
    <>
      <Typography variant="h3" className={`${classes.all} ${classes.title}`}>
        Subject Enrollment
      </Typography>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.gridRoot}
        // style={{ minHeight: "100vh" }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          className={
            largeScreen
              ? classes.existingSubjectButton
              : classes.existingSubjectButtonXS
          }
        >
          {/* () => history.push(`/mdt/${linkTo}/`)} */}
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() =>
              history.push(`/mdt/stroke/subjectenrollment/search/`)
            }
          >
            Search for Existing Subject
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" className={classes.ORText}>
            OR
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className={
            largeScreen
              ? classes.addNewSubjectButton
              : classes.addNewSubjectButtonXS
          }
        >
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => history.push(`/mdt/stroke/subjectenrollment/add/`)}
          >
            Add New Subject
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SubjectEnrollment.propTypes = {};

export default SubjectEnrollment;
