import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyAuu6uo2f-2ffTsPhJPn5RnAbaEHf8bNRQ",
  authDomain: "performancephenomics.firebaseapp.com",
  databaseURL: "https://performancephenomics.firebaseio.com",
  projectId: "performancephenomics",
  storageBucket: "performancephenomics.appspot.com",
  messagingSenderId: "1075803197937",
  appId: "1:1075803197937:web:1a9eb4b263118c10df7450",
  measurementId: "G-HF5WYQR42F",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
