import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import { setErrorMessage } from "../../../../store/reducers/features/auth/authSlice";
import {
  loginErrorMessage,
  parseMillisecondsIntoReadableTime,
} from "../../utils/helperFunctions";

const LoginError = ({ setLoginDisabled }) => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState("");
  let error = localStorage.getItem("error");

  useEffect(() => {
    let loginAttempts = parseInt(localStorage.getItem("loginAttempts"));
    if (loginAttempts % 3 === 0) {
      setTimeout(() => {
        setLoginDisabled(true);
        let rightNow = new Date();
        let getRetryDate = new Date(localStorage.getItem("retryDate"));

        let diff = getRetryDate.getTime() - rightNow.getTime();
        if (diff >= 0) {
          setTimeLeft(parseMillisecondsIntoReadableTime(diff));
        } else {
          localStorage.setItem("loginAttempts", parseInt(loginAttempts) + 1);
          localStorage.setItem("error", "");
          dispatch(setErrorMessage(null));
          setLoginDisabled(false);
        }
      }, 1000);
    }
  });
  return (
    <div>
      <Alert severity="error">
        <AlertTitle>
          {!!error &&
            loginErrorMessage(
              parseInt(localStorage.getItem("loginAttempts")),
              timeLeft,
              error
            ).title}
        </AlertTitle>
        <br />
        {!!error &&
          loginErrorMessage(
            parseInt(localStorage.getItem("loginAttempts")),
            timeLeft,
            error
          ).body}
      </Alert>
    </div>
  );
};

LoginError.propTypes = {};

export default LoginError;
