import * as React from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField } from "formik-material-ui";

import StatusWithToolTip from "./StatusWithToolTip";

const nihssStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    maxWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const NIHSS = ({ updateFormData, isButtonLoading, formValues }) => {
  const classes = nihssStyles();
  const [expanded, setExpanded] = React.useState(false);

  let completed = formValues.NIHSS_Completed;

  const initialValues = {
    Level_of_consciousness: 0,
    LOC_questions: 0,
    LOC_commands: 0,
    Best_gaze: 0,
    Visual: 0,
    Facial_palsy: 0,
    Left_motor_arm: 0,
    Left_motor_arm_explain: "",
    Right_motor_arm: 0,
    Right_motor_arm_explain: "",
    Left_motor_leg: 0,
    Left_motor_leg_explain: "",
    Right_motor_leg: 0,
    Right_motor_leg_explain: "",
    Limb_ataxia: 0,
    Limb_ataxia_explain: "",
    Dysarthria: 0,
    Dysarthria_explain: "",
    Sensory: 0,
    Best_language: 0,
    Extinction_inattention: 0,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const nihssSchema = {
    levelOfConsciousness: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
    ],
    locQuestions: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
    ],
    locCommands: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
    ],
    bestGaze: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
    ],
    visual: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
    ],
    facialPalsy: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
    ],
    motorArm: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: "un", label: "UN" },
    ],
    motorLeg: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: "un", label: "UN" },
    ],
    limbAtaxia: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: "un", label: "UN" },
    ],
    sensory: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
    ],
    bestLanguage: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
    ],
    dysarthria: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: "un", label: "UN" },
    ],
    extinctInattention: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
    ],
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        // validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => {
          values.Total_score_outof_42_nihss =
            parseInt(values.Level_of_consciousness) +
            parseInt(values.LOC_questions) +
            parseInt(values.LOC_commands) +
            parseInt(values.Best_gaze) +
            parseInt(values.Visual) +
            parseInt(values.Facial_palsy) +
            parseInt(
              values.Left_motor_arm === "un" ? 0 : values.Left_motor_arm
            ) +
            parseInt(
              values.Right_motor_arm === "un" ? 0 : values.Right_motor_arm
            ) +
            parseInt(
              values.Left_motor_leg === "un" ? 0 : values.Left_motor_leg
            ) +
            parseInt(
              values.Right_motor_leg === "un" ? 0 : values.Right_motor_leg
            ) +
            parseInt(values.Limb_ataxia === "un" ? 0 : values.Limb_ataxia) +
            parseInt(values.Dysarthria === "un" ? 0 : values.Dysarthria) +
            parseInt(values.Sensory) +
            parseInt(values.Best_language) +
            parseInt(values.Extinction_inattention);
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <StatusWithToolTip completed={completed} />
                      <Typography className={classes.heading}>NIHSS</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ paddingLeft: "10px" }}
                      >
                        <Grid item xs={4}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Level of Consciousness
                            </Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Level_of_consciousness"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.levelOfConsciousness.map(
                                  (option, index) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">LOC Questions</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="LOC_questions"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.locQuestions.map(
                                  (option, index) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">LOC Commands</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="LOC_commands"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.locCommands.map(
                                  (option, index) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={4} style={{ marginTop: "10px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Best Gaze</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Best_gaze"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.bestGaze.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "10px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Visual</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Visual"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.visual.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Facial Palsy</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Facial_palsy"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.facialPalsy.map(
                                  (option, index) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Left Motor Arm</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Left_motor_arm"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.motorArm.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                // component={TextField}
                                style={{ width: "99%", marginTop: "5px" }}
                                as="textarea"
                                rows={3}
                                disabled={
                                  values.Left_motor_arm === "un" ? false : true
                                }
                                name="Left_motor_arm_explain"
                                label="Explain"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Right Motor Arm
                            </Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Right_motor_arm"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.motorArm.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                // component={TextField}
                                style={{ width: "99%", marginTop: "5px" }}
                                as="textarea"
                                rows={3}
                                disabled={
                                  values.Right_motor_arm === "un" ? false : true
                                }
                                name="Right_motor_arm_explain"
                                label="Explain"
                              />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Left Motor Leg</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Left_motor_leg"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.motorLeg.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                // component={TextField}
                                style={{ width: "99%", marginTop: "5px" }}
                                as="textarea"
                                rows={3}
                                disabled={
                                  values.Left_motor_leg === "un" ? false : true
                                }
                                name="Left_motor_leg_explain"
                                label="Explain"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Right Motor Leg
                            </Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Right_motor_leg"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.motorLeg.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                // component={TextField}
                                style={{ width: "99%", marginTop: "5px" }}
                                as="textarea"
                                rows={3}
                                disabled={
                                  values.Right_motor_leg === "un" ? false : true
                                }
                                name="Right_motor_leg_explain"
                                label="Explain"
                              />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Limb Ataxia</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Limb_ataxia"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.limbAtaxia.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                // component={TextField}
                                style={{ width: "99%", marginTop: "5px" }}
                                as="textarea"
                                rows={3}
                                disabled={
                                  values.Limb_ataxia === "un" ? false : true
                                }
                                name="Limb_ataxia_explain"
                                label="Explain"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Dysarthria</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Dysarthria"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.dysarthria.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                // component={TextField}
                                style={{ width: "99%", marginTop: "5px" }}
                                as="textarea"
                                rows={3}
                                disabled={
                                  values.Dysarthria === "un" ? false : true
                                }
                                name="Dysarthria_explain"
                                label="Explain"
                              />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Sensory</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Sensory"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.sensory.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Best Language</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Best_language"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.bestLanguage.map(
                                  (option, index) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Extinction and Inattention
                            </Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Extinction_inattention"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {nihssSchema.extinctInattention.map(
                                  (option, index) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "26px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography variant="h6">
                        Total Score: {values.Total_score_outof_42_nihss}/42
                      </Typography>
                    </div>
                    <div>
                      <Button
                        disabled={!dirty}
                        size="small"
                        onClick={() =>
                          updateFormData(
                            "completeButton",
                            completed,
                            values,
                            "NIHSS_Completed"
                          )
                        }
                        style={{
                          backgroundColor: !dirty
                            ? "grey"
                            : completed
                            ? "#CD6F00"
                            : "#276700",
                          color: "#ffffff",
                          marginRight: "10px",
                        }}
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : completed ? (
                          <>Mark Incomplete</>
                        ) : (
                          <>Mark Complete</>
                        )}
                      </Button>
                      <Button
                        disabled={!dirty}
                        onClick={() =>
                          updateFormData(
                            "saveButton",
                            completed,
                            values,
                            "NIHSS_Completed"
                          )
                        }
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </div>
                  </AccordionActions>
                </Accordion>
              </Form>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    </div>
  );
};
export default NIHSS;
