export function parseMillisecondsIntoReadableTime(milliseconds) {
  //Get hours from milliseconds
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

  return h + " hours " + m + " minutes " + s + " seconds ";
}

export const loginErrorMessage = (loginAttempts, timeLeft, error) => {
  let message = { title: null, body: null };
  if (loginAttempts % 3 === 0) {
    message.title = `Login Error - Limit of attempts reached`;
    message.body = `Try Again in ${timeLeft}`;
  } else {
    message.title = `Login Error - ${loginAttempts % 3}/3 Attempts`;
    message.body = error;
  }
  return message;
};
