import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { useStyles } from "./AddSubjectStyles";
import {
  //   Box,
  //   Button,
  CircularProgress,
  //   FormControl,
  //   Grid,
  //   InputLabel,
  //   MenuItem,
  //   Select,
  //   TextField,
  //   Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
// import { DatePicker } from "formik-material-ui-pickers";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";

// const sexChoices = [
//   { value: "null", label: "None" },
// { value: "male", label: "Male" },
// { value: "female", label: "Female" },
// { value: "unspecified", label: "Unspecified" },
// ];
// const AddSubject = (props) => {
//   const classes = useStyles();

//   let history = useHistory();
//   const [aliasList, setAliasList] = useState();
//   const [subjectID, setSubjectID] = useState(null);
//   // const [searchValue, setSearchValue] = useState("");
//   const [searchedSubject, setSearchedSubject] = useState("");
//   const [errorText, setErrorText] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   // const [session, setSession] = useState("");
//   const [isDialogOpen, setIsDialogOpen] = useState(false);
//   const [date, setDate] = useState("");
//   // const [visitNumber, setVisitNumber] = useState("");
//   // const [sessionID, setSessionID] = useState(null);
//   const [subjectFirst, setSubjectFirst] = useState(null);
//   const [subjectLast, setSubjectLast] = useState(null);
//   const [subjectSex, setSubjectSex] = useState(null);
//   const [subjectDOB, setSubjectDOB] = useState(null);

//   const apiRoot =
//     process.env.NODE_ENV === "production"
//       ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
//       : "http://127.0.0.1:8000";
//   const ENDPOINT = `${apiRoot}/aliases/`;
//   const { accessToken } = useSelector(
//     (state) => state.firebase.auth.stsTokenManager
//   );
//   useEffect(() => {
//     getAliases();
//   }, []);
//   const getAliases = () => {
//     // console.log("fetch data");
//     setIsLoading(true);

//     fetch(ENDPOINT, {
//       method: "GET",
//       credentials: "include",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `JWT ${accessToken}`,
//       },
//     })
//       .then((response) => response.json())
//       .then((result) => {
//         setAliasList(result);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };
//   const padNumber = (n, length) => {
//     var len = length - ("" + n).length;
//     return (len > 0 ? new Array(++len).join("0") : "") + n;
//   };
//   const generateAlias = (aliasList) => {
//     let max = 0;
//     let baseAlias = "STR_";
//     aliasList.map((alias) => {
//       let currentAliasNumber = parseInt(alias.alias.split("_")[1]);
//       if (currentAliasNumber >= max) {
//         max = currentAliasNumber;
//       }
//     });
//     const newAlias = baseAlias + padNumber(max + 1, 3);
//     return newAlias;
//   };
//   // const handleSearchChange = (event) => {
//   //   setSearchValue(event.target.value, event);
//   // };
//   const ValidationSchema = Yup.object().shape({
//     // Age: Yup.number().required().positive().lessThan(150),
//     // Sex: Yup.string().required(),
//   });
//   return (
//     <div className={classes.root}>
//       {isLoading ? (
//         <CircularProgress />
//       ) : (
//         !!aliasList && (
//           <div>
//             <h1 style={{ textAlign: "center" }}>Add Stroke Subject</h1>
//             <Formik
//               initialValues={{
//                 StudyID: generateAlias(aliasList),
//                 Date: null,
//                 VisitNumber: 1,
//                 Sex: sexChoices[0].value,
//                 Age: null,
//                 first_name: "",
//                 last_name: "",
//               }}
//               enableReinitialize
//               validationSchema={ValidationSchema}
//               onSubmit={(values, { setSubmitting }) => {
//                 setTimeout(() => {
//                   setSubmitting(false);
//                   if (values.Date) {
//                     values.Date = format(values.Date, "yyyy-MM-dd");
//                   }
//                   console.log(values.first_name);
//                   alert(JSON.stringify(values, null, 2));
//                   // let sessionID = 5;
//                   // let visitNumber = 1;
//                   // history.push(
//                   //   `/mdt/stroke/?subjectid=${values.StudyID}&sex=${values.Sex}&age=${values.Age}&sessionid=${sessionID}&session=${values.Date}&visitnumber=${visitNumber}`
//                   // );
//                 }, 500);
//               }}
//             >
//               {({ submitForm, isSubmitting, touched, errors }) => (
//                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                   <Form>
//                     <Grid
//                       container
//                       direction="row"
//                       justify="center"
//                       alignItems="flex-start"
//                     >
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography variant="h6">
//                             Generated SubjectID
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography variant="h5">
//                             {generateAlias(aliasList)}
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography
//                             variant="h6"
//                             style={{ paddingTop: "30px" }}
//                           >
//                             Sex
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Field
//                             component={TextField}
//                             type="text"
//                             name="Sex"
//                             label="Sex"
//                             required
//                             select
//                             variant="standard"
//                             margin="normal"
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                             style={{ width: "200px" }}
//                           >
//                             {SEX_CHOICES.map((option) => (
//                               <MenuItem key={option.value} value={option.value}>
//                                 {console.log(option)}
//                                 {option.label}
//                               </MenuItem>
//                             ))}
//                           </Field>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography
//                             variant="h6"
//                             style={{ paddingTop: "20px" }}
//                           >
//                             Session Date
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1} style={{ textAlign: "center" }}>
//                           <Field
//                             component={DatePicker}
//                             style={{ width: "200px" }}
//                             name="Date"
//                             label="Session Date"
//                             required
//                           />
//                         </Box>
//                       </Grid>

//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography
//                             variant="h6"
//                             style={{ paddingTop: "15px" }}
//                           >
//                             Age
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Field
//                             component={TextField}
//                             type="number"
//                             name="Age"
//                             label="Age"
//                             style={{ width: "200px" }}
//                             required
//                           />
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography
//                             variant="h6"
//                             style={{ paddingTop: "15px" }}
//                           >
//                             First Name
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Field
//                             component={TextField}
//                             type="text"
//                             name="first_name"
//                             label="First Name"
//                             style={{ width: "200px" }}
//                             required
//                           />
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Typography
//                             variant="h6"
//                             style={{ paddingTop: "15px" }}
//                           >
//                             Last Name
//                           </Typography>
//                         </Box>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Box margin={1}>
//                           <Field
//                             component={TextField}
//                             type="text"
//                             name="last_name"
//                             label="Last Name"
//                             style={{ width: "200px" }}
//                             required
//                           />
//                         </Box>
//                       </Grid>

//                       <Grid item xs={12}>
//                         <Box margin={1}>
//                           <Button
//                             variant="contained"
//                             color="primary"
//                             disabled={isSubmitting}
//                             onClick={submitForm}
//                           >
//                             Submit
//                           </Button>
//                         </Box>
//                       </Grid>
//                     </Grid>
//                   </Form>
//                 </MuiPickersUtilsProvider>
//               )}
//             </Formik>
//           </div>
//         )
//       )}
//     </div>
//   );
// };

// AddSubject.propTypes = {};

// export default AddSubject;
// import * as React from "react";
import { render } from "react-dom";
import { Formik, Form, Field } from "formik";
import {
  Button,
  LinearProgress,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";
import {
  fieldToTextField,
  TextField,
  TextFieldProps,
  Select,
  Switch,
} from "formik-material-ui";
import {
  TimePicker,
  DatePicker,
  DateTimePicker,
} from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Autocomplete,
  ToggleButtonGroup,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";

const sexChoices = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "", label: "Unspecified" },
];

function UpperCasingTextField(props) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue(name, value ? value.toUpperCase() : "");
    },
    [setFieldValue, name]
  );
  return <MuiTextField {...fieldToTextField(props)} onChange={onChange} />;
}

const AddSubject = () => {
  const classes = useStyles();

  let history = useHistory();
  let testSubjectURL;
  const [aliasList, setAliasList] = useState();
  const [subjectURL, setSubjectURL] = useState();
  const [subjectID, setSubjectID] = useState(null);
  // const [searchValue, setSearchValue] = useState("");
  const [searchedSubject, setSearchedSubject] = useState("");
  const [errorText, setErrorText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [session, setSession] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [date, setDate] = useState("");
  // const [visitNumber, setVisitNumber] = useState("");
  // const [sessionID, setSessionID] = useState(null);
  const [subjectFirst, setSubjectFirst] = useState(null);
  const [subjectLast, setSubjectLast] = useState(null);
  const [subjectSex, setSubjectSex] = useState(null);
  const [subjectDOB, setSubjectDOB] = useState(null);

  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";
  const ENDPOINT = `${apiRoot}/aliases/`;
  const SUBJECTS_ENDPOINT = `${apiRoot}/subjects/`;
  const ALIASES_ENDPOINT = `${apiRoot}/aliases/`;
  const SESSIONS_ENDPOINT = `${apiRoot}/sessions/`;
  const STROKE_STUDY_ENDPOINT = `${apiRoot}/stroke_study/`;
  const { accessToken } = useSelector(
    (state) => state.firebase.auth.stsTokenManager
  );
  useEffect(() => {
    getAliases();
  }, []);
  const createSubject = (fetchURL, values) => {
    setIsLoading(true);
    let subjectSchema = {
      sex: values.Sex,
    };

    return fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(subjectSchema),
    }).then((response) => {
      // console.log("subject: ", response.json());
      return response.json();
    });
  };
  const createAlias = (fetchURL, values, subjectURL) => {
    let aliasSchema = {
      alias: values.StudyID,
      subject: subjectURL,
    };
    return fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(aliasSchema),
    }).then((response) => {
      // console.log("alias: ", response.json());
      return response.json();
    });
  };
  const createSession = (fetchURL, values, subjectURL) => {
    let sessionSchema = {
      date: values.Date,
      subject: subjectURL,
    };
    return fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(sessionSchema),
    }).then((response) => {
      return response.json();
    });
  };
  const createStrokeStudy = (
    fetchURL,
    values,
    subjectURL,
    sessionURL,
    date
  ) => {
    let strokeStudySchema = {
      StudyID: values.StudyID,
      Date: date,
      VisitNumber: 1,
      Sex: values.Sex,
      Age: values.Age,
      subject: subjectURL,
      session: sessionURL,
    };
    console.log(strokeStudySchema);
    return fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(strokeStudySchema),
    })
      .then((response) => response.json())
      .then((strokeStudy) => {
        let strokeID = strokeStudy.url.split("/").slice(-2)[0];
        history.push(`/mdt/stroke/?visitnumber=${1}&strokeid=${strokeID}`);
        console.log("strokestudy: ", strokeStudy);
        setIsLoading(false);
      });
  };

  const getAliases = () => {
    // console.log("fetch data");
    setIsLoading(true);

    fetch(ENDPOINT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setAliasList(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const padNumber = (n, length) => {
    var len = length - ("" + n).length;
    return (len > 0 ? new Array(++len).join("0") : "") + n;
  };
  const generateAlias = (aliasList) => {
    let max = 0;
    let baseAlias = "STR_";
    aliasList.map((alias) => {
      let currentAliasNumber = parseInt(alias.alias.split("_")[1]);
      if (currentAliasNumber >= max) {
        max = currentAliasNumber;
      }
    });
    const newAlias = baseAlias + padNumber(max + 1, 3);
    return newAlias;
  };

  const ValidationSchema = Yup.object().shape({
    Age: Yup.number()
      .required("Age is required")
      .positive()
      .lessThan(150, "Too old! Must be less than 150")
      .nullable(),
    Sex: Yup.string().required("Sex is required").nullable(),
    Date: Yup.string().required("Session Date is required").nullable(),
  });
  return (
    <div className={classes.root}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        !!aliasList && (
          <div>
            <h1 style={{ textAlign: "center" }}>Add Stroke Subject</h1>
            <Formik
              initialValues={{
                StudyID: generateAlias(aliasList),
                Date: null,
                VisitNumber: 1,
                Sex: sexChoices[2].value,
                Age: null,
              }}
              // validationSchema={ValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                if (values.Date) {
                  values.Date = format(values.Date, "yyyy-MM-dd");
                }

                createSubject(SUBJECTS_ENDPOINT, values)
                  .then((subject) => {
                    return Promise.all([
                      createAlias(ALIASES_ENDPOINT, values, subject.url),
                      createSession(SESSIONS_ENDPOINT, values, subject.url),
                    ]);
                  })
                  .then(([_, session]) =>
                    createStrokeStudy(
                      STROKE_STUDY_ENDPOINT,
                      values,
                      session.subject,
                      session.url,
                      session.date
                    )
                  );
              }}
            >
              {({
                submitForm,
                isSubmitting,
                isValid,
                dirty,
                touched,
                errors,
              }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Form>
                    <Box margin={1}>
                      <Field
                        component={TextField}
                        disabled
                        style={{ width: "200px" }}
                        value={generateAlias(aliasList)}
                        type="text"
                        label="SubjectID"
                        name="StudyID"
                      />
                    </Box>
                    <Box margin={1}>
                      <Field
                        component={DatePicker}
                        style={{ width: "200px" }}
                        name="Date"
                        label="Session Date"
                      />
                    </Box>
                    <Box margin={1}>
                      <Field
                        component={TextField}
                        name="Age"
                        type="number"
                        label="Age"
                        style={{ width: "200px" }}
                      />
                    </Box>
                    <Box margin={1}>
                      <Field
                        component={TextField}
                        type="text"
                        name="Sex"
                        label="Sex"
                        style={{ width: "200px" }}
                        select
                        variant="standard"
                        helperText="Please select Range"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {sexChoices.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Box>

                    {isSubmitting && <LinearProgress />}

                    <Box margin={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!(dirty && isValid)}
                        // disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                </MuiPickersUtilsProvider>
              )}
            </Formik>
          </div>
        )
      )}
    </div>
  );
};
export default AddSubject;
