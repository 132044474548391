import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectAxisLabel = ({
  axisLabels,
  setAxisLabels,
  axisLabel,
  setAxisLabel,
  allData,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    getAllAvailableKeys(allData[0]);
  }, []);

  const getAllAvailableKeys = (allData) => {
    let arr = [];
    for (const [key, value] of Object.entries(allData)) {
      if (value !== "NaN") {
        arr.push(key);
      }
    }
    setAxisLabels(arr);
    setHasLoaded(true);
    return null;
  };

  const handleChange = (event) => {
    setAxisLabel({ ...axisLabel, y: event.target.value });
  };
  return (
    <div>
      {hasLoaded ? (
        <FormControl required>
          <InputLabel id="demo-simple-select-required-label">
            Dependent Axis
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={axisLabel.y}
            onChange={handleChange}
            style={{ minWidth: "120px" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {axisLabels.map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <div>Loading Axis </div>
      )}
    </div>
  );
};

export default SelectAxisLabel;
