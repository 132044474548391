import React from "react";
import PropTypes from "prop-types";

import { useStyles } from "./MetadataToolStyles";
import { useState } from "react";
import { StudyTypeCards } from "./components/StudyTypeCards";
import { Container, Grid } from "@material-ui/core";
import { MTBI_ROLE, STROKE_ROLE } from "../../roles";
import { usePermission } from "../../hooks/permissions/usePermission";
import PermissionDenied from "../PermissionDenied/PermissionDenied";

const roles = [STROKE_ROLE, MTBI_ROLE];

const MetadataTool = (props) => {
  const classes = useStyles();

  const [selectedStudy, setSelectedStudy] = useState("None");
  let hasPermission = usePermission(roles);

  const studyCard = [
    {
      title: "Stroke Study",
      description: "Fill out stroke study form",
      role: usePermission([STROKE_ROLE]),
      linkTo: "stroke/subjectenrollment/",
    },
    {
      title: "mTBI Study",
      description: "Fill out mTBI study form",
      role: usePermission([MTBI_ROLE]),
      linkTo: "mtbi",
    },
  ];

  if (!hasPermission) return <PermissionDenied />;
  return (
    <div>
      <Container className={classes.root} fluid={1}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {studyCard.map(({ title, description, linkTo, role }, index) => {
            return (
              role && (
                <Grid item xs={4} key={index}>
                  <StudyTypeCards
                    title={title}
                    description={description}
                    linkTo={linkTo}
                  />
                </Grid>
              )
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

MetadataTool.propTypes = {};

export default MetadataTool;
