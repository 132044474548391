import { createMuiTheme } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#272c34",
    },
    secondary: pink,
  },
});
