import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: { maxWidth: 345 },
}));
// export const demographicStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(25),
//     fontWeight: "bold",
//     flexBasis: "33.33%",
//     flexShrink: 0,
//     maxWidth: "200px",
//   },
//   secondaryHeading: {
//     paddingTop: "10px",
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
// }));
// export const comorbiditiesStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(25),
//     fontWeight: "bold",
//     flexBasis: "33.33%",
//     flexShrink: 0,
//     maxWidth: "200px",
//   },
//   secondaryHeading: {
//     paddingTop: "10px",
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
// }));
// export const mainStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(25),
//     fontWeight: "bold",
//     flexBasis: "33.33%",
//     flexShrink: 0,
//     maxWidth: "200px",
//   },
//   secondaryHeading: {
//     paddingTop: "10px",
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
// }));
