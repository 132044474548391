import { makeStyles } from "@material-ui/core";
import { CONTAINER_MAX_WIDTH } from "../../utils/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
  },
  mainText: {
    color: theme.palette.error.main,
    marginBottom: "20px",
  },
  secondaryText: {
    color: theme.palette.error.light,
  },
}));
