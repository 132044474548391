import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./EventListStyles";
import { Grid } from "@material-ui/core";

const EventList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { eventList } = useSelector((state) => state.tsa);
  return (
    <div>
      {eventList.map(({ event, time, time_column, value, value_column }) => {
        return (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4} style={{ marginTop: "10px" }}>
                Event: {event}
              </Grid>
              <Grid item xs={4} style={{ marginTop: "10px" }}>
                {time_column}: {time}
              </Grid>
              <Grid item xs={4} style={{ marginTop: "10px" }}>
                {value_column}: {value}
              </Grid>
            </Grid>
            <hr />
          </>
        );
      })}
    </div>
  );
};

export default EventList;
