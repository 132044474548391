import React, { useState } from "react";
import { NavBar } from "./components/NavBar";
import { SideBar } from "./components/SideBar";

const GlobalNav = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <nav>
      <NavBar toggleSidebar={toggleSidebar} />
      <SideBar open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </nav>
  );
};

export default GlobalNav;
