import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useStyles } from "./NavBarStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../store/reducers/features/auth/authSlice";

const NavBar = ({ toggleSidebar = () => null }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isNotSmall = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const { uid } = useSelector((state) => state.firebase.auth);
  const { isAuthenticating } = useSelector((state) => state.auth);

  const handleLogoutClick = () => {
    dispatch(logout()).then(() => history.push("/login"));
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          {!!uid && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography className={classes.heading} variant="h6" color="inherit">
            {isNotSmall && (
              <span className={classes.ppText}>Performance Phenomics </span>
            )}
            Internal
          </Typography>

          {!!uid && (
            <Button
              variant="text"
              color="inherit"
              disabled={isAuthenticating}
              onClick={handleLogoutClick}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
