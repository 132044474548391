import { useSelector } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";

export const usePermission = (allowedRoles = []) => {
  const roles = useSelector(({ firebase: { profile } }) => profile.roles);
  if (!isLoaded(roles)) return false;
  if (isEmpty(roles)) return false;
  if (!allowedRoles.length) return true;
  return (
    allowedRoles.some((r) => roles.includes(r)) || !!roles.includes("admin")
  );
};
