import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./DropdownListStyles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const DropdownList = ({
  list,
  disabled = false,
  dataName,
  setDataName,
  nameOfList,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setDataName(event);
  };
  return (
    <div>
      <FormControl required className={classes.formControl} disabled={disabled}>
        <InputLabel id={nameOfList}>{nameOfList}</InputLabel>
        <Select
          labelId={`${nameOfList}-list-label`}
          id={`${nameOfList}-list`}
          value={dataName}
          onChange={handleChange}
          className={classes.selectEmpty}
        >
          <MenuItem value="None">
            <em>None</em>
          </MenuItem>
          {list &&
            list.map(({ id, text }) => <MenuItem value={id}>{text}</MenuItem>)}
        </Select>
        <FormHelperText>Required</FormHelperText>
      </FormControl>
    </div>
  );
};

DropdownList.propTypes = {};

export default DropdownList;
