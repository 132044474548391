import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    maxWidth: "800px",
    margin: "0 auto",
    textAlign: "center",
  },
  gridRoot: { textAlign: "left", marginTop: "50px" },
  searchField: { width: "90%" },
  searchButtonContainer: { textAlign: "left" },
  searchButton: { marginTop: "10px" },
}));
