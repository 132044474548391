import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { useStyles } from "./FileDropStyles";
import axios from "axios";

const FileDrop = ({ session, tokens }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let formData = new FormData();
  // const apiRoot = "http://127.0.0.1:8000";
  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";
  const ENDPOINT_TIMESERIES = `${apiRoot}/timeseriesfiles/`;
  let sessionUrl = `${apiRoot}/sessions/${session.toString()}/`;

  formData.append("session", sessionUrl);
  const postFile = (file, session) => {
    formData.append("timeseries_file", file[0]);

    fetch(ENDPOINT_TIMESERIES, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `JWT ${tokens.accessToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) =>
        console.log("--add redux here at some point Response: ", data)
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={session === "None"}
      >
        Upload File
      </Button>
      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        filesLimit={1}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(file) => {
          postFile(file, "testUser", "2001-10-10");
          setOpen(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </div>
  );
};
export default FileDrop;
