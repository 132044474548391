import React from "react";
import { Container, Grid } from "@material-ui/core";
import { useStyles } from "./HomePageStyles";
import { HomeCard } from "./components/HomeCard";
import { Link } from "react-router-dom";
import {
  ADMIN_ROLE,
  EMPLOYEE_ROLE,
  MDT_ROLE,
  MTBI_ROLE,
  STROKE_ROLE,
} from "../../roles";
import { usePermission } from "../../hooks/permissions/usePermission";
import PermissionDenied from "../PermissionDenied/PermissionDenied";

const roles = [ADMIN_ROLE, STROKE_ROLE, MTBI_ROLE];
const HomePage = () => {
  const classes = useStyles();

  let hasPermission = usePermission(roles);
  // if (!hasPermission) return <PermissionDenied />;
  const cardData = [
    {
      title: "Timeseries Annotation Tool",
      description: "Upload, view and annotate data such as breath hold.",
      imgPath: require("./photos/graphPP.png"),
      imgTitleAlt: "Line Graph",
      role: usePermission([ADMIN_ROLE]),
      linkProps: {
        component: Link,
        to: "/tsa",
      },
    },
    {
      title: "Metabase",
      description:
        "View all of our data in a formatted, searchable and easy to use way.",
      imgPath: require("./photos/metabasePP.png"),
      imgTitleAlt: "Metabase",
      role: usePermission([ADMIN_ROLE]),
      linkProps: {
        target: "_blank",
        href:
          "http://performancephenomics-metabase.us-east-1.elasticbeanstalk.com/",
      },
    },
    {
      title: "Subject Intake Tool",
      description:
        "Tool to collect metadata about a subject for a particular study",
      imgPath: require("./photos/subjectIntakePP.png"),
      imgTitleAlt: "Subject Intake Tool",
      role: usePermission([STROKE_ROLE, MTBI_ROLE]),
      linkProps: {
        component: Link,
        to: "/mdt",
      },
    },
  ];

  return (
    <Container className={classes.root} fluid={1}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        spacing={3}
      >
        {cardData.map(
          (
            { title, description, imgPath, imgTitleAlt, linkProps, role },
            index
          ) => {
            return (
              role && (
                <Grid item xs={12} sm={4} key={index}>
                  <HomeCard
                    title={title}
                    description={description}
                    imgPath={imgPath}
                    imgTitleAlt={imgTitleAlt}
                    linkProps={linkProps}
                  />
                </Grid>
              )
            );
          }
        )}
      </Grid>
    </Container>
  );
};

export default HomePage;
