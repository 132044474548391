import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import SendIcon from "@material-ui/icons/Send";
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LaunchIcon from "@material-ui/icons/Launch";
import { useStyles } from "./SideBarStyles";
import { Link } from "react-router-dom";
import { MetabaseIcon } from "../../../MetabaseIcon";
import { usePermission } from "../../../../hooks/permissions/usePermission";
import { ADMIN_ROLE, MTBI_ROLE, STROKE_ROLE } from "../../../../roles";

const SideBar = ({ open = false, toggleSidebar = () => null }) => {
  const classes = useStyles();

  const sidebarItem = [
    {
      to: "/",
      icon: <HomeIcon />,
      role: usePermission([STROKE_ROLE, MTBI_ROLE]),
      text: "Home",
    },
    {
      to: "/tsa",
      icon: <TimelineIcon />,
      role: usePermission([ADMIN_ROLE]),
      text: "Timeseries Annotation",
    },
    {
      to: "/",
      icon: <SendIcon />,
      role: usePermission([ADMIN_ROLE]),
      text: "Dsub Jobs",
    },
    {
      to: "/",
      icon: <ListAltIcon />,
      role: usePermission([ADMIN_ROLE]),
      text: "Data Catalogue",
    },
  ];
  let renderMetabase = usePermission([ADMIN_ROLE]);

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={toggleSidebar}>
        <div className={classes.toolbar} />

        <Divider />

        <List className={classes.list}>
          {sidebarItem.map((item, index) => {
            return (
              item.role && (
                <ListItem
                  button
                  onClick={toggleSidebar}
                  component={Link}
                  to={item.to}
                  key={index}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              )
            );
          })}
          <Divider />
          {renderMetabase && (
            <ListItem
              button
              onClick={toggleSidebar}
              component="a"
              target="_blank"
              href={
                "http://performancephenomics-metabase.us-east-1.elasticbeanstalk.com/"
              }
            >
              <ListItemIcon>
                <MetabaseIcon />
              </ListItemIcon>
              <ListItemText>
                Metabase{" "}
                <LaunchIcon fontSize="small" className={classes.launchIcon} />
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default SideBar;
