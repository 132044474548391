import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./SubjectListDropdownStyles";

import { DropdownList } from "../DropdownList";
import { Grid } from "@material-ui/core";

const SubjectListDropdown = ({ subjectList, session, setSession }) => {
  const classes = useStyles();
  const [subject, setSubject] = React.useState("None");
  const [sessionList, setSessionList] = React.useState(null);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
    getSessions(event.target.value, subjectList);
  };
  const handleSessionChange = (event) => {
    setSession(event.target.value);
  };
  const getSessions = (id, list) => {
    if (id === "None") return setSessionList(null);
    const index = list.findIndex((x) => x.id === id);
    setSessionList(list[index].sessions);
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DropdownList
            list={subjectList.map(({ id, first_name }) => ({
              id,
              text: first_name,
            }))}
            dataName={subject}
            setDataName={handleSubjectChange}
            nameOfList={"Subject"}
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownList
            list={
              sessionList !== null &&
              sessionList.map(({ id, date }) => ({
                id,
                text: date,
              }))
            }
            dataName={session}
            setDataName={handleSessionChange}
            nameOfList={"Session"}
            disabled={sessionList === null}
          />
        </Grid>
      </Grid>
    </div>
  );
};

SubjectListDropdown.propTypes = {};

export default SubjectListDropdown;
