import { makeStyles } from "@material-ui/core";
import { TOOLBAR_HEIGHT } from "../../utils/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${theme.spacing(TOOLBAR_HEIGHT)}px)`,
    display: "flex",
    alignItems: "center",
  },
  paper: {
    marginBottom: theme.spacing(12),
  },
}));
