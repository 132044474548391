import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { AnonymousRoute } from "./components/AnonymousRoute";
import { GlobalNav } from "../components/GlobalNav";
import { HomePage } from "../components/HomePage";
import { LoginPage } from "../components/LoginPage";
import { TimeSeriesAnnotationListPage } from "../components/TimeSeriesAnnotationListPage";
import { TimeSeriesAnnotationDetailPage } from "../components/TimeSeriesAnnotationDetailPage";
import MetadataTool from "../components/MetadataTool/MetadataTool";
import StrokeStudy from "../components/MetadataTool/components/StrokeStudy/StrokeStudy";
import { MtbiStudy } from "../components/MetadataTool/components/MTBIStudy";
import SubjectEnrollment from "../components/MetadataTool/components/SubjectEnrollment/SubjectEnrollment";
import AddSearchSubject from "../components/MetadataTool/components/SubjectEnrollment/components/AddSearchSubject/AddSearchSubject";

const App = () => {
  const { uid } = useSelector((state) => state.firebase.auth);

  return (
    <Router>
      <div className="App">
        <GlobalNav />
        <Switch>
          <AuthenticatedRoute
            exact
            path="/"
            authenticated={!!uid}
            component={HomePage}
          />
          <AuthenticatedRoute
            exact
            path="/tsa"
            authenticated={!!uid}
            component={TimeSeriesAnnotationListPage}
          />
          <AuthenticatedRoute
            path="/tsa/:id"
            authenticated={!!uid}
            component={TimeSeriesAnnotationDetailPage}
          />
          <AuthenticatedRoute
            // path="/mdt/:studyType/subjectenrollment/:enrollmenttype/"
            path="/mdt/stroke/subjectenrollment/:enrollmenttype/"
            authenticated={!!uid}
            component={AddSearchSubject}
          />
          <AuthenticatedRoute
            path="/mdt/stroke/subjectenrollment"
            authenticated={!!uid}
            component={SubjectEnrollment}
          />
          <AuthenticatedRoute
            path="/mdt/stroke/"
            authenticated={!!uid}
            component={StrokeStudy}
          />
          <AuthenticatedRoute
            path="/mdt/mtbi/"
            authenticated={!!uid}
            component={MtbiStudy}
          />
          <AuthenticatedRoute
            path="/mdt"
            authenticated={!!uid}
            component={MetadataTool}
          />
          {/* <AuthenticatedRoute
            path="/stroke"
            authenticated={!!uid}
            component={StrokeStudy}
          /> */}
          <AnonymousRoute
            path="/login"
            authenticated={!!uid}
            component={LoginPage}
            redirectPath={"/"}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
