import React, { useEffect } from "react";
import Plot from "react-plotly.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./GraphStyles";

const Graph = ({ lineData, setClicked, xLabel, yLabel, eventList, evl }) => {
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [revision, setRevision] = useState(0);
  let yMax = Math.max(...lineData.y);
  let ev = [];
  const [events, setEvents] = useState([]);
  const layout = {
    title: `${xLabel} vs ${yLabel}`,
    width: 1200,
    height: 350,
    xaxis: {
      title: `${xLabel}`,
    },
    yaxis: {
      title: `${yLabel}`,
    },
  };

  useEffect(() => {
    ev.push({
      x: lineData.x,
      y: lineData.y,
      type: "scatter",
      mode: "lines",
      marker: { color: "red" },
    });
    eventList.map(({ time, value_column }) =>
      ev.push({
        x: [time, time],
        y: [0, yMax],
        mode: "lines",
        marker: { color: value_column === yLabel ? "black" : "grey" },
      })
    );
    setEvents(ev);
    console.log(events);
  }, [lineData, eventList]);

  useEffect(() => {}, [events]);

  const handleClick = (e) => {
    setClicked({ x: e.points[0].x, y: e.points[0].y });
  };
  const classes = useStyles();

  return (
    <div>
      {eventList.length >= 0 && events.length >= 0 ? (
        <Plot
          onClick={(event) => handleClick(event)}
          data={events}
          layout={layout}
          revision={revision}
          scrollZoom={true}
        />
      ) : (
        <div>waiting for events to load</div>
      )}
    </div>
  );
};

export default Graph;
