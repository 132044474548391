//react
import React, { useState, useEffect } from "react";
//redux
import { useDispatch } from "react-redux";
import { addTSEvent } from "../../../../store/reducers/features/tsa/tsaSlice";
//components
import { useStyles } from "./AddEventsFormStyles";
//mui
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@material-ui/core";

function getSteps() {
  return [
    "Select start point of event",
    "Select end point of event",
    "Select Type of Event",
  ];
}

let eventTime;
let eventValue;
let secondClick;
const eventsToSubmit = {
  time: null,
  time_column: "",
  value: null,
  value_column: "",
  event: null,
  timeseries: null,
};
const AddEventsForm = ({
  clicked,
  setClicked,
  tokens,
  timeseriesIdUrl,
  axisLabel,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [eventType, setEventType] = React.useState("");
  const [events, setEvents] = useState(null);
  let isEventTypeSelectDisabled = true;
  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";
  //   const apiRoot = "http://127.0.0.1:8000";
  const ENDPOINT_TIMESERIES_EVENTS = `${apiRoot}/timeseriesevents/`;

  useEffect(() => {
    getEventTypes(ENDPOINT_TIMESERIES_EVENTS);
  }, []);
  const getEventTypes = (ENDPOINT_TIMESERIES_EVENTS) => {
    fetch(ENDPOINT_TIMESERIES_EVENTS, {
      method: "OPTIONS",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${tokens.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => setEvents(result.actions.POST.event.choices))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const stepperContent = (clicked) => {
    return (
      <>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={clicked}
          disabled
          helperText="Click Point on Graph"
        />
        <FormControl
          required
          className={classes.formControl}
          disabled={isEventTypeSelectDisabled}
        >
          <InputLabel id="demo-simple-select-required-label">
            Event Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={eventType}
            onChange={handleChange}
            className={classes.selectEmpty}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {events.map(({ display_name, value }) => (
              <MenuItem value={value}>{display_name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>Required</FormHelperText>
        </FormControl>
      </>
    );
  };

  const handleChange = (event) => {
    setEventType(event.target.value);
  };

  const steps = getSteps();
  function getStepContent(stepIndex, clicked, eventTime) {
    switch (stepIndex) {
      case 0:
        return stepperContent(clicked);
      case 1:
        isEventTypeSelectDisabled = false;
        return stepperContent(clicked);
      case 2:
        isEventTypeSelectDisabled = true;
        return stepperContent(clicked);
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = (activeStep) => {
    if (activeStep === 0) {
      eventTime = clicked.x;
      eventValue = clicked.y;
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 2) {
      eventsToSubmit.time = eventTime;
      eventsToSubmit.time_column = axisLabel.x;
      eventsToSubmit.value = eventValue;
      eventsToSubmit.value_column = axisLabel.y;
      eventsToSubmit.event = eventType;
      eventsToSubmit.timeseries = timeseriesIdUrl;
      addEvent(ENDPOINT_TIMESERIES_EVENTS, tokens);

      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const addEvent = (ENDPOINT_TIMESERIES_EVENTS, tokens) => {
    fetch(ENDPOINT_TIMESERIES_EVENTS, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${tokens.accessToken}`,
      },
      body: JSON.stringify(eventsToSubmit),
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(addTSEvent(result));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    isEventTypeSelectDisabled = true;
    setEventType("");
    setClicked(0);
  };

  if (events === null) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep, clicked.x, eventTime, secondClick)}
              </Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext(activeStep)}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default AddEventsForm;
