import React from "react";
import { Route, Redirect } from "react-router-dom";

const AnonymousRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${rest.redirectPath}`} />
        )
      }
    />
  );
};

export default AnonymousRoute;
