import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid, TextField } from "@material-ui/core";
import { SubjectListDropdown } from "../SubjectListDropdown";
import { useStyles } from "./TimeSeriesAnnotationListPageStyles";
import { AllTimeSeriesList } from "../AllTimeSeriesList";
import { FileDrop } from "../FileDrop";
import { ADMIN_ROLE } from "../../roles";
import { usePermission } from "../../hooks/permissions/usePermission";
import PermissionDenied from "../PermissionDenied/PermissionDenied";

const roles = [ADMIN_ROLE];

const TimeSeriesAnnotationListPage = ({ tokens, profile }) => {
  const classes = useStyles();
  const [subjectList, setSubjectList] = useState();
  const [session, setSession] = React.useState("None");
  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";
  const ENDPOINT = `${apiRoot}/subjects/with_timeseries/`;

  useEffect(() => {
    getSubjects();
  }, []);

  const getSubjects = () => {
    fetch(ENDPOINT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${tokens.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => setSubjectList(result))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  let hasPermission = usePermission(roles);
  if (!hasPermission) return <PermissionDenied />;

  return (
    <Container className={classes.root} fluid={1}>
      <h1>Timeseries Annotation List</h1>
      {subjectList === (null || undefined) ? (
        <span>Loading...</span>
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SubjectListDropdown
                subjectList={subjectList}
                session={session}
                setSession={setSession}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: "32px" }}>
              <FileDrop session={session} tokens={tokens} />
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <hr />
          </div>
          <div>
            <AllTimeSeriesList subjectList={subjectList} />
          </div>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    tokens: state.firebase.auth.stsTokenManager,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(TimeSeriesAnnotationListPage);
