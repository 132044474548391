import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StatusWithToolTip from "./StatusWithToolTip";

const bartelIndexStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    minWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const BartelIndex = ({ updateFormData, isButtonLoading, formValues }) => {
  const classes = bartelIndexStyles();
  const [expanded, setExpanded] = React.useState(false);

  let completed = formValues.Bartel_Index_Completed;

  const initialValues = {
    Feeding: 0,
    Bathing: 0,
    Grooming: 0,
    Dressing: 0,
    Bowels: 0,
    Bladder: 0,
    Toilet_use: 0,
    Transfers: 0,
    Mobility_bartel: 0,
    Stairs: 0,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const bartel = {
    five: [
      { value: 0, label: 0 },
      { value: 5, label: 5 },
    ],
    ten: [
      { value: 0, label: 0 },
      { value: 5, label: 5 },
      { value: 10, label: 10 },
    ],
    fifteen: [
      { value: 0, label: 0 },
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
    ],
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        // validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => {
          values.Total_score_outof_100_bartel =
            parseInt(values.Feeding) +
            parseInt(values.Bathing) +
            parseInt(values.Grooming) +
            parseInt(values.Dressing) +
            parseInt(values.Bowels) +
            parseInt(values.Bladder) +
            parseInt(values.Toilet_use) +
            parseInt(values.Transfers) +
            parseInt(values.Mobility_bartel) +
            parseInt(values.Stairs);
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <StatusWithToolTip completed={completed} />
                      <Typography className={classes.heading}>
                        Bartel Index
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ paddingLeft: "10px" }}
                      >
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Feeding</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Feeding"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.ten.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Bathing</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Bathing"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.five.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Grooming</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Grooming"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.five.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Dressing</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Dressing"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.ten.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Bowels</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Bowels"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.ten.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Bladder</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Bladder"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.ten.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Toilet Use</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Toilet_use"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.ten.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Transfers</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Transfers"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.fifteen.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Mobility</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Mobility_bartel"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.fifteen.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">Stairs</Typography>
                            <div style={{ width: "90%", marginTop: "10px" }}>
                              <Field
                                name="Stairs"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                {bartel.ten.map((option, index) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6} />
                      </Grid>
                    </>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "26px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography variant="h6">
                        Total Score: {values.Total_score_outof_100_bartel}/100
                      </Typography>
                    </div>
                    <div>
                      <Button
                        disabled={!dirty}
                        size="small"
                        onClick={() =>
                          updateFormData(
                            "completeButton",
                            completed,
                            values,
                            "Bartel_Index_Completed"
                          )
                        }
                        style={{
                          backgroundColor: !dirty
                            ? "grey"
                            : completed
                            ? "#CD6F00"
                            : "#276700",
                          color: "#ffffff",
                          marginRight: "10px",
                        }}
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : completed ? (
                          <>Mark Incomplete</>
                        ) : (
                          <>Mark Complete</>
                        )}
                      </Button>
                      <Button
                        disabled={!dirty}
                        onClick={() =>
                          updateFormData(
                            "saveButton",
                            completed,
                            values,
                            "Bartel_Index_Completed"
                          )
                        }
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </div>
                  </AccordionActions>
                </Accordion>
              </Form>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    </div>
  );
};
export default BartelIndex;
