import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  title: { marginTop: "50px", textAlign: "center" },
  gridRoot: { marginTop: "50px" },
  existingSubjectButton: { textAlign: "right" },
  existingSubjectButtonXS: { textAlign: "center" },
  addNewSubjectButton: { textAlign: "left" },
  addNewSubjectButtonXS: { textAlign: "center" },
  ORText: { textAlign: "center" },
}));
