import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { TextField } from "formik-material-ui";
import StatusWithToolTip from "./StatusWithToolTip";

const snapStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    maxWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Snap = ({ updateFormData, isButtonLoading, formValues }) => {
  const classes = snapStyles();
  const [expanded, setExpanded] = React.useState(false);

  let completed = formValues.Snap_Completed;

  const initialValues = {
    Line_cancellation: 0,
    Line_bisection: 0,
    Shape_cancellation_outof_30: 0,
    Drawing_and_copying: 0,
  };

  const ValidationSchema = Yup.object().shape({
    Line_cancellation: Yup.number()
      .min(0, "Must be greater than 0")
      .max(10, "Must be <= 10")
      .nullable(),
    Line_bisection: Yup.number()
      .min(0, "Must be greater than 0")
      .max(30, "Must be <= 30")
      .nullable(),
    Shape_cancellation_outof_30: Yup.number()
      .min(0, "Must be greater than 0")
      .max(30, "Must be <= 30")
      .nullable(),
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const snapClassification = (number) => {
    if (number <= 5) {
      return <span style={{ color: "green" }}>Normal Performance</span>;
    } else if (5 < number && number <= 40) {
      return <span style={{ color: "#CD6F00" }}>Mild Neglect</span>;
    } else if (40 < number && number <= 100) {
      return <span style={{ color: "red" }}>Severe Neglect</span>;
    }
  };
  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => {
          values.Total_score_outof_100_snap =
            parseInt(values.Line_cancellation) +
            parseInt(values.Line_bisection) +
            parseInt(values.Shape_cancellation_outof_30) +
            parseInt(values.Drawing_and_copying);

          if (!!!values.Line_cancellation) {
            values.Line_cancellation = 0;
          }
          if (!!!values.Line_bisection) {
            values.Line_bisection = 0;
          }
          if (!!!values.Shape_cancellation_outof_30) {
            values.Shape_cancellation_outof_30 = 0;
          }

          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <StatusWithToolTip completed={completed} />
                      <Typography className={classes.heading}>SNAP</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ paddingLeft: "10px" }}
                      >
                        <Grid item xs={6}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Drawing and Copying
                            </Typography>
                            <div style={{ width: "50%", marginTop: "20px" }}>
                              <Field
                                name="Drawing_and_copying"
                                as="select"
                                style={{ width: "100%", height: "1.5rem" }}
                              >
                                <option value={0}>0</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                              </Field>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Line Cancellation /10
                            </Typography>
                            <div style={{ width: "100%" }}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Line_cancellation"
                                // label="Line Cancellation"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Line Bisection (/30)
                            </Typography>
                            <div style={{ width: "100%" }}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Line_bisection"
                                // label="Line Bisection"
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            margin={1}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              Shape Cancellation (/30)
                            </Typography>
                            <div style={{ width: "100%" }}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Shape_cancellation_outof_30"
                                // label="Shape Cancellation"
                              />
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "26px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography variant="h6">
                        Total Score: {values.Total_score_outof_100_snap}/100
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "15px" }}
                      >
                        {snapClassification(values.Total_score_outof_100_snap)}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        disabled={!(dirty && isValid)}
                        size="small"
                        onClick={() =>
                          updateFormData(
                            "completeButton",
                            completed,
                            values,
                            "Snap_Completed"
                          )
                        }
                        style={{
                          backgroundColor:
                            !dirty || !isValid
                              ? "grey"
                              : completed
                              ? "#CD6F00"
                              : "#276700",
                          color: "#ffffff",
                          marginRight: "10px",
                        }}
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : completed ? (
                          <>Mark Incomplete</>
                        ) : (
                          <>Mark Complete</>
                        )}
                      </Button>
                      <Button
                        disabled={!(dirty && isValid)}
                        onClick={() =>
                          updateFormData(
                            "saveButton",
                            completed,
                            values,
                            "Snap_Completed"
                          )
                        }
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        {isButtonLoading ? (
                          <CircularProgress size="1.75rem" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </div>
                  </AccordionActions>
                </Accordion>
              </Form>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    </div>
  );
};
export default Snap;
