import { makeStyles } from "@material-ui/core";
import { TOOLBAR_HEIGHT } from "../../../../utils/constants";

export const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  toolbar: {
    minHeight: theme.spacing(TOOLBAR_HEIGHT),
  },
  launchIcon: {
    verticalAlign: "sub",
  },
}));
