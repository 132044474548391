import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    thunk: {
      extraArgument: { getFirebase, getFirestore },
    },
  }),
});

export default store;
