import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./SearchedSubjectLayoutStyles";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

const SearchedSubjectLayout = ({
  handleSessionChange,
  subjectList,
  searchedSubject,
  setIsDialogOpen,
  setSubjectFirst,
  setSubjectLast,
  setSubjectDOB,
  setSubjectSex,
  setSubjectURL,
  strokeID,
  setIsAddSessionDialogOpen,
  setStrokeSessionVN,
}) => {
  const classes = useStyles();

  const findIndexWithAttr = (array, attr, value, subArray) => {
    for (var i = 0; i < array.length; i += 1) {
      let newArray = array[i][subArray];
      for (var j = 0; j < newArray.length; j += 1) {
        if (newArray[j][attr] === value) {
          return i;
        }
      }
    }
    return -1;
  };

  const getIndexOfSearchedUser = (searchedSubject) => {
    // returns index of the seachedSubject in list of all subjects
    let index = findIndexWithAttr(
      subjectList,
      "alias",
      searchedSubject,
      "aliases"
    );
    setSubjectFirst(subjectList[index].first_name);
    setSubjectLast(subjectList[index].last_name);
    setSubjectSex(subjectList[index].sex);
    setSubjectDOB(subjectList[index].date_of_birth);
    setSubjectURL(subjectList[index].url);
    setStrokeSessionVN(subjectList[index].sessions.length + 1);
    return index;
  };
  let strokeArray =
    subjectList[getIndexOfSearchedUser(searchedSubject)]["stroke_study"];
  return (
    !!subjectList && (
      <>
        <Grid item xs={9} className={classes.subjectIDTitle}>
          <Typography variant="h4">Subject ID</Typography>
        </Grid>
        <Grid item xs={3} className={classes.sessionTitle}>
          <Typography variant="h4">Session</Typography>
        </Grid>
        <Grid item xs={9} className={classes.subjectIDValue}>
          <Typography variant="h6">{searchedSubject}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.sessionValueContainer}>
          {subjectList[getIndexOfSearchedUser(searchedSubject)].stroke_study
            .length > 0 ? (
            <>
              <FormControl className={classes.sessionDropdown}>
                <InputLabel id="demo-simple-select-helper-label">
                  Session
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={strokeID}
                  onChange={(event, x) => handleSessionChange(event, x)}
                >
                  <MenuItem value="">
                    <em>Date, Visit Number</em>
                  </MenuItem>

                  {subjectList[
                    getIndexOfSearchedUser(searchedSubject)
                  ].sessions.map((session, index) => {
                    let vn = index + 1;

                    let sessionWithStrokeID = strokeArray.findIndex(
                      (stroke) => stroke.session === session.url
                    );
                    return sessionWithStrokeID === -1 ? null : (
                      <MenuItem
                        value={strokeArray[sessionWithStrokeID].id}
                        name={session.date}
                      >{`${session.date}, ${vn}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          ) : (
            <div>No Sessions with stroke forms exist</div>
          )}
        </Grid>

        <Grid item xs={12} className={classes.confirmButton}>
          {console.log(strokeID)}
          <Button
            variant="contained"
            color="primary"
            disabled={
              subjectList[getIndexOfSearchedUser(searchedSubject)].stroke_study
                .length < 1 || !!!strokeID
            }
            // disabled={!!!strokeID}
            onClick={() => setIsDialogOpen(true)}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => setIsAddSessionDialogOpen(true)}
          >
            Add New Sroke Session
          </Button>
        </Grid>
      </>
    )
  );
};

SearchedSubjectLayout.propTypes = {};

export default SearchedSubjectLayout;
