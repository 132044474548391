import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  FormControlLabel,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField, Switch } from "formik-material-ui";
import StatusWithToolTip from "./StatusWithToolTip";

const comorbiditiesStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    maxWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const StrokeComorbidities = ({
  updateFormData,
  isButtonLoading,
  formValues,
}) => {
  const classes = comorbiditiesStyles();
  const [expanded, setExpanded] = React.useState(false);
  let completed = formValues.Comorbidities_Completed;

  const initialValues = {
    Hypertension: false,
    Dyslipidemia: false,
    Diabetes: false,
    Peripheral_vascular_disease: false,
    Peripheral_vascular_disease_note: "",
    Coronary_heart_disease: false,
    Coronary_heart_disease_note: "",
    Current_smoker_tobacco: false,
    Current_smoker_tobacco_cig_per_day: null,
    Current_smoker_tobacco_num_of_years: null,
    Former_smoker_tobacco: false,
    Former_smoker_tobacco_cig_per_day: null,
    Former_smoker_tobacco_num_of_years: null,
  };

  const ValidationSchema = Yup.object().shape({
    Current_smoker_tobacco_num_of_years: Yup.number()
      .label("Years Smoked")
      .min(0, "Must be positive number")
      .nullable(),
    Current_smoker_tobacco_cig_per_day: Yup.number()
      .label("Cigarettes per day")
      .min(0, "Must be positive number")
      .nullable(),
    Former_smoker_tobacco_num_of_years: Yup.number()
      .label("Years Smoked")
      .min(0, "Must be positive number")
      .nullable(),
    Former_smoker_tobacco_cig_per_day: Yup.number()
      .label("Cigarettes per day")
      .min(0, "Must be positive number")
      .nullable(),
    Coronary_heart_disease_note: Yup.string()
      .min(0)
      .max(150, "Max 150 Characters")
      .nullable(),
    Peripheral_vascular_disease_note: Yup.string()
      .min(0)
      .max(150, "Max 150 Characters")
      .nullable(),
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <StatusWithToolTip completed={completed} />
                    <Typography className={classes.heading}>
                      Comorbidities
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ paddingLeft: "10px" }}
                    >
                      <Grid item xs={4}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="Hypertension"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Hypertension"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="Dyslipidemia"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Dyslipidemia"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="Diabetes"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Diabetes"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          margin={1}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControlLabel
                            label="Peripheral Vascular Disease"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Peripheral_vascular_disease"
                              />
                            }
                          />
                          <div style={{ width: "100%" }}>
                            <Field
                              component={TextField}
                              type="text"
                              multiline
                              name="Peripheral_vascular_disease_note"
                              label="Describe"
                              disabled={!values.Peripheral_vascular_disease}
                            />
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          margin={1}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControlLabel
                            label="Coronary Heart Disease"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Coronary_heart_disease"
                              />
                            }
                          />
                          <div style={{ width: "100%" }}>
                            <Field
                              component={TextField}
                              type="text"
                              multiline
                              name="Coronary_heart_disease_note"
                              label="Describe"
                              disabled={!values.Coronary_heart_disease}
                            />
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          margin={1}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControlLabel
                            label="Current tobacco smoker"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Current_smoker_tobacco"
                              />
                            }
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Current_smoker_tobacco_num_of_years"
                                label="Years smoked"
                                disabled={!values.Current_smoker_tobacco}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Current_smoker_tobacco_cig_per_day"
                                label="Cigarettes/day"
                                disabled={!values.Current_smoker_tobacco}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "left" }}>
                        <Box margin={1}>
                          <FormControlLabel
                            label="Former tobacco smoker"
                            control={
                              <Field
                                component={Switch}
                                type="checkbox"
                                name="Former_smoker_tobacco"
                              />
                            }
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Former_smoker_tobacco_num_of_years"
                                label="Years smoked"
                                disabled={!values.Former_smoker_tobacco}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Field
                                component={TextField}
                                type="number"
                                name="Former_smoker_tobacco_cig_per_day"
                                label="Cigarettes/day"
                                disabled={!values.Former_smoker_tobacco}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    disabled={!(dirty && isValid)}
                    size="small"
                    onClick={() =>
                      updateFormData(
                        "completeButton",
                        completed,
                        values,
                        "Comorbidities_Completed"
                      )
                    }
                    style={{
                      backgroundColor:
                        !dirty || !isValid
                          ? "grey"
                          : completed
                          ? "#CD6F00"
                          : "#276700",
                      color: "#ffffff",
                    }}
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : completed ? (
                      <>Mark Incomplete</>
                    ) : (
                      <>Mark Complete</>
                    )}
                  </Button>
                  <Button
                    disabled={!(dirty && isValid)}
                    onClick={() =>
                      updateFormData(
                        "saveButton",
                        completed,
                        values,
                        "Comorbidities_Completed"
                      )
                    }
                    size="small"
                    variant="outlined"
                    color="primary"
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </AccordionActions>
              </Accordion>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    </div>
  );
};
export default StrokeComorbidities;
