import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useStyles } from "./PermissionDeniedStyles";

const PermissionDenied = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.mainText}>
        Permission Denied
      </Typography>
      <Typography color="error" variant="h6" className={classes.secondaryText}>
        Contact administrator to review your access roles.
      </Typography>
    </div>
  );
};

PermissionDenied.propTypes = {};

export default PermissionDenied;
