import { combineReducers } from "redux";
import authReducer from "./features/auth/authSlice";
import tsaReducer from "./features/tsa/tsaSlice";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  tsa: tsaReducer,
  auth: authReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export default rootReducer;
