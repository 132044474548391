import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./StudyTypeCardsStyles";
import {
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

const StudyTypeCards = ({ title, description, linkTo }) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div onClick={() => history.push(`/mdt/${linkTo}/`)}>
      {/* <div> */}
      <Card className={classes.root}>
        <CardActionArea>
          {/* <ButtonBase component={Link} to={`/${linkTo}`}> */}
          {/* <ButtonBase target="_blank" href={linkTo}> */}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
          {/* </ButtonBase> */}
        </CardActionArea>
      </Card>
    </div>
  );
};

export default StudyTypeCards;
