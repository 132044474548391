import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./SearchSubjectStyles";
import SearchedSubjectLayout from "./components/SearchedSubjectLayout/SearchedSubjectLayout";
import { useHistory } from "react-router-dom";
import differenceInYears from "date-fns/differenceInYears";
import { Field, Form, Formik } from "formik";
import { format } from "date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  TimePicker,
  DatePicker,
  DateTimePicker,
} from "formik-material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

const SearchSubject = () => {
  const classes = useStyles();
  let history = useHistory();
  const [subjectList, setSubjectList] = useState();
  const [subjectExists, setSubjectExists] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchedSubject, setSearchedSubject] = useState("");
  const [errorText, setErrorText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [date, setDate] = useState("");
  const [visitNumber, setVisitNumber] = useState("");
  const [sessionID, setSessionID] = useState(null);
  const [subjectFirst, setSubjectFirst] = useState(null);
  const [subjectLast, setSubjectLast] = useState(null);
  const [subjectSex, setSubjectSex] = useState(null);
  const [subjectDOB, setSubjectDOB] = useState(null);
  const [subjectURL, setSubjectURL] = useState(null);
  const [strokeID, setStrokeID] = useState("");
  const [isAddSessionDialogOpen, setIsAddSessionDialogOpen] = useState(false);
  const [strokeSessionVN, setStrokeSessionVN] = useState(null);
  const [newSessionAge, setNewSessionAge] = useState(0);
  const [isInputValid, setIsInputValid] = useState(true);

  const handleNewSessionAgeChange = (e) => {
    setNewSessionAge(e.target.value);
  };

  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";
  const ENDPOINT = `${apiRoot}/subjects/with_strokestudy/`;
  const SESSIONS_ENDPOINT = `${apiRoot}/sessions/`;
  const STROKE_STUDY_ENDPOINT = `${apiRoot}/stroke_study/`;
  const { accessToken } = useSelector(
    (state) => state.firebase.auth.stsTokenManager
  );
  useEffect(() => {
    getSubjects();
  }, []);
  const getSubjects = () => {
    setIsLoading(true);

    fetch(ENDPOINT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setSubjectList(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value, event);
  };

  const searchFieldIsValid = (value) => {
    let valueSplit = value.split("_");
    if (valueSplit.length !== 2) {
      return false;
    }
    let firstPartIsSTR = value.split("_")[0] === "STR";
    let secondPartHasLength3 = value.split("_")[1].length === 3;
    let secondPartIsNumber = !!parseInt(value.split("_")[1]);

    if (!(firstPartIsSTR && secondPartHasLength3 && secondPartIsNumber)) {
      return false;
    }
    return true;
  };

  const handleSearchClick = () => {
    let found = false;
    setStrokeID("");
    if (!searchFieldIsValid(searchValue)) return setIsInputValid(false);
    setIsInputValid(true);

    for (let i = 0; i < subjectList.length; i++) {
      found = subjectList[i].aliases.some(
        (alias) => alias["alias"] === searchValue
      );
      if (found) break;
    }

    setSearchedSubject(searchValue);
    setSubjectExists(found);
    if (!found) return setErrorText("No Subjects Found");
    setSearchValue("");
  };
  const createSession = (fetchURL, values, subjectURL) => {
    let sessionSchema = {
      date: values.Date,
      subject: subjectURL,
    };
    return fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(sessionSchema),
    }).then((response) => {
      return response.json();
    });
  };
  const createStrokeStudy = (
    fetchURL,
    values,
    subjectURL,
    sessionURL,
    date
  ) => {
    let strokeStudySchema = {
      StudyID: searchedSubject,
      Date: date,
      VisitNumber: values.VisitNumber,
      Sex: values.Sex,
      Age: newSessionAge,
      subject: subjectURL,
      session: sessionURL,
    };
    console.log(strokeStudySchema);
    return fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(strokeStudySchema),
    })
      .then((response) => response.json())
      .then((strokeStudy) => {
        let strokeID = strokeStudy.url.split("/").slice(-2)[0];
        history.push(
          `/mdt/stroke/?visitnumber=${values.VisitNumber}&strokeid=${strokeID}`
        );
        setIsLoading(false);
      });
  };
  let vn;
  let d;
  const handleSessionChange = (event, x) => {
    try {
      vn = x.props.children.split(",")[1];
      vn = vn.replace(/\s/g, "");
      d = x.props.children.split(",")[0];
      setVisitNumber(vn);
      setStrokeID(event.target.value);
      setSession(d);
    } catch (error) {
      return null;
    }
  };
  const ValidationSchema = Yup.object().shape({
    Age: Yup.number().required("Age is required").positive(),
    // .lessThan(150, "Too old! Must be less than 150")
    // .nullable(),
    Date: Yup.string().required("Session Date is required").nullable(),
  });

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridRoot}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs={9}>
              <TextField
                id="subject-search"
                label="Enter Subject ID"
                type="search"
                value={searchValue}
                onChange={handleSearchChange}
                className={classes.searchField}
              />
            </Grid>
            <Grid item xs={3} className={classes.searchButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.searchButton}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </Grid>
            {isInputValid ? (
              subjectExists ? (
                <>
                  <SearchedSubjectLayout
                    subjectList={subjectList}
                    handleSessionChange={handleSessionChange}
                    searchedSubject={searchedSubject}
                    setIsDialogOpen={setIsDialogOpen}
                    setSubjectFirst={setSubjectFirst}
                    setSubjectLast={setSubjectLast}
                    setSubjectDOB={setSubjectDOB}
                    setSubjectSex={setSubjectSex}
                    setSubjectURL={setSubjectURL}
                    strokeID={strokeID}
                    setIsAddSessionDialogOpen={setIsAddSessionDialogOpen}
                    setStrokeSessionVN={setStrokeSessionVN}
                  />
                  <Dialog
                    open={isAddSessionDialogOpen}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle
                      id="alert-dialog-slide-title"
                      style={{ minWidth: "600px" }}
                    >
                      {"Create New Stroke Study Session"}
                    </DialogTitle>
                    <DialogContent>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Formik
                          initialValues={{
                            StudyID: searchedSubject,
                            Date: null,
                            VisitNumber: strokeSessionVN,
                            Sex: subjectSex,
                            Age: newSessionAge,
                          }}
                          // validationSchema={ValidationSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            if (values.Date) {
                              values.Date = format(values.Date, "yyyy-MM-dd");
                            }
                            createSession(
                              SESSIONS_ENDPOINT,
                              values,
                              subjectURL
                            ).then((session) =>
                              createStrokeStudy(
                                STROKE_STUDY_ENDPOINT,
                                values,
                                session.subject,
                                session.url,
                                session.date
                              )
                            );
                          }}
                        >
                          {({
                            values,
                            submitForm,
                            isSubmitting,
                            isValid,
                            dirty,
                            touched,
                            errors,
                          }) => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              {/* {console.log(dirty, isValid)} */}
                              <Form>
                                <Box margin={1}>
                                  <Field
                                    component={TextField}
                                    disabled
                                    style={{ width: "200px" }}
                                    value={searchedSubject}
                                    type="text"
                                    label="SubjectID"
                                    name="StudyID"
                                  />
                                </Box>
                                <Box margin={1}>
                                  <Field
                                    component={DatePicker}
                                    style={{ width: "200px" }}
                                    name="Date"
                                    label="Session Date"
                                  />
                                </Box>
                                <Box margin={1}>
                                  <Field
                                    component={TextField}
                                    name="Age"
                                    type="number"
                                    label="Age"
                                    value={newSessionAge}
                                    onChange={handleNewSessionAgeChange}
                                    style={{ width: "200px" }}
                                  />
                                </Box>

                                {isSubmitting && <LinearProgress />}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Box margin={1}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      // disabled={isSubmitting}
                                      onClick={() =>
                                        setIsAddSessionDialogOpen(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </Box>
                                  <Box margin={1}>
                                    {/* {console.log(!!values.Date && !!values.Age)} */}
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={!!!values.Date}
                                      // disabled={isSubmitting}
                                      onClick={submitForm}
                                    >
                                      Submit
                                    </Button>
                                  </Box>
                                </div>
                              </Form>
                            </MuiPickersUtilsProvider>
                          )}
                        </Formik>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <span style={{ color: "red" }}>{errorText}</span>
              )
            ) : (
              <span style={{ color: "red" }}>Invalid Input</span>
            )}
          </>
        )}
      </Grid>

      <div>
        <Dialog
          open={isDialogOpen}
          //   TransitionComponent={Transition}
          keepMounted
          //   onClose={setIsDialogOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            style={{ minWidth: "600px" }}
          >
            {"Confirm your choice"}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={6}>
                <Typography variant="h6">SubjectID</Typography>
                <Typography variant="subtitle1">Sex: {subjectSex}</Typography>
                <Typography variant="subtitle1">
                  Age:{" "}
                  {differenceInYears(new Date(session), new Date(subjectDOB))}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">{searchedSubject}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Session</Typography>
                <Typography variant="subtitle1">
                  Visit Number: {visitNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">{session}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="primary">
              Disagree
            </Button>
            <Button
              color="primary"
              onClick={() =>
                history.push(
                  `/mdt/stroke/?visitnumber=${visitNumber}&strokeid=${strokeID}`
                )
              }
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

SearchSubject.propTypes = {};

export default SearchSubject;
