import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StatusWithToolTip from "./StatusWithToolTip";

const modifiedRankinScaleStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    minWidth: "300px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ModifiedRankinScale = ({
  updateFormData,
  isButtonLoading,
  formValues,
}) => {
  const classes = modifiedRankinScaleStyles();
  const [expanded, setExpanded] = React.useState(false);

  let completed = formValues.Modified_Rankin_Scale_Completed;

  const initialValues = {
    modified_rankin_scale_symptoms: 0,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const radioSelections = [
    { label: "No symptoms", value: 0 },
    { label: "No significant disability despite symptoms", value: 1 },
    { label: "Slight disability", value: 2 },
    { label: "Moderate disability", value: 3 },
    { label: "Moderately severe disability", value: 4 },
    { label: "Severe disability", value: 5 },
    { label: "Death", value: 6 },
  ];
  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        // validationSchema={ValidationSchema}
      >
        {({ values, isValid, dirty }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
              {" "}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <StatusWithToolTip completed={completed} />
                    <Typography className={classes.heading}>
                      Modified Rankin Scale
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ paddingLeft: "10px" }}
                    >
                      <Grid item xs={3}>
                        <Box
                          margin={1}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="h6">Symptom Severity</Typography>
                          <div style={{ width: "100%", marginTop: "10px" }}>
                            <Field
                              name="modified_rankin_scale_symptoms"
                              as="select"
                              style={{ width: "100%", height: "1.5rem" }}
                            >
                              {radioSelections.map((option, index) => (
                                <option value={option.value}>
                                  {`${index} - ${option.label}`}
                                </option>
                              ))}
                            </Field>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    disabled={!(dirty && isValid)}
                    size="small"
                    onClick={() =>
                      updateFormData(
                        "completeButton",
                        completed,
                        values,
                        "Modified_Rankin_Scale_Completed"
                      )
                    }
                    style={{
                      backgroundColor:
                        !dirty || !isValid
                          ? "grey"
                          : completed
                          ? "#CD6F00"
                          : "#276700",
                      color: "#ffffff",
                    }}
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : completed ? (
                      <>Mark Incomplete</>
                    ) : (
                      <>Mark Complete</>
                    )}
                  </Button>
                  <Button
                    disabled={!(dirty && isValid)}
                    onClick={() =>
                      updateFormData(
                        "saveButton",
                        completed,
                        values,
                        "Modified_Rankin_Scale_Completed"
                      )
                    }
                    size="small"
                    variant="outlined"
                    color="primary"
                  >
                    {isButtonLoading ? (
                      <CircularProgress size="1.75rem" />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </AccordionActions>
              </Accordion>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    </div>
  );
};
export default ModifiedRankinScale;
