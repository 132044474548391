import * as React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  AccordionActions,
  Divider,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Select } from "formik-material-ui";
import { TimePicker } from "formik-material-ui-pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { format } from "date-fns";
import StatusWithToolTip from "./StatusWithToolTip";

const mainStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    minWidth: "200px",
  },
  secondaryHeading: {
    paddingTop: "10px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ClinicalDetails = ({
  updateFormData,
  isButtonLoading,
  formValues,
  strokeTreatment,
}) => {
  const classes = mainStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [selectedDate, setDate] = React.useState(
    !!formValues.Date_of_stroke_presentation
      ? new Date(formValues.Date_of_stroke_presentation.replace(/-/g, "/"))
      : new Date()
  );
  const clinicalDetailsTreatment = [
    "Intravenous tPA",
    "Intraarterial tPA",
    "Mechanical thrombectomy",
  ];
  var initTime = new Date(
    `Tue, 24 Apr 2012 ${formValues.Time_of_stroke_presentation} -0400`
  );
  const [selectedTime, setTime] = React.useState(initTime);
  let completed = formValues.Clinical_Details_Completed;

  const initialValues = {
    Date_of_stroke_presentation: null,
    Time_of_stroke_presentation: null,
    Treatment_received: [],
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDateChange = (date) => {
    setDate(date);
    formValues["Date_of_stroke_presentation"] = format(
      new Date(date),
      "yyyy-MM-dd"
    );
  };
  const handleTimeChange = (time) => {
    setTime(time);
    formValues["Time_of_stroke_presentation"] = format(
      new Date(time),
      "HH:mm:ss"
    );
  };

  return (
    !!strokeTreatment && (
      <div className={classes.root}>
        <Formik
          initialValues={formValues || initialValues}
          enableReinitialize
          // validationSchema={ValidationSchema}
        >
          {({ values, isValid, dirty }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <StatusWithToolTip completed={completed} />
                      <Typography className={classes.heading}>
                        Clinical Details
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ paddingLeft: "10px" }}
                      >
                        <Grid item xs={4}>
                          <Box margin={1}>
                            <Field
                              component={KeyboardDatePicker}
                              style={{ minWidth: "80%" }}
                              name="Date_of_stroke_presentation"
                              label="Date of stroke presentation"
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="yyyy-MM-dd"
                              value={selectedDate}
                              onChange={(date) => handleDateChange(date)}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box margin={1}>
                            <Field
                              component={TimePicker}
                              style={{ minWidth: "80%" }}
                              name="Time_of_stroke_presentation"
                              label="Time of stroke presentation"
                              ampm={false}
                              value={selectedTime}
                              onChange={(time) => handleTimeChange(time)}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box margin={1}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel shrink={true} htmlFor="treatment">
                                Treatment Received
                              </InputLabel>
                              <Field
                                component={Select}
                                type="text"
                                name="Treatment_received"
                                multiple={true}
                                // inputProps={{ name: "tags", id: "tags" }}
                              >
                                {strokeTreatment.map((options, index) => (
                                  <MenuItem value={options.url}>
                                    {options.treatment}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions>
                    <Button
                      // disabled={!dirty}
                      size="small"
                      onClick={() =>
                        updateFormData(
                          "completeButton",
                          completed,
                          values,
                          "Clinical_Details_Completed"
                        )
                      }
                      style={{
                        backgroundColor:
                          //  !dirty
                          //   ? "grey"
                          //   :
                          completed ? "#CD6F00" : "#276700",
                        color: "#ffffff",
                      }}
                    >
                      {isButtonLoading ? (
                        <CircularProgress size="1.75rem" />
                      ) : completed ? (
                        <>Mark Incomplete</>
                      ) : (
                        <>Mark Complete</>
                      )}
                    </Button>
                    <Button
                      // disabled={!dirty}
                      onClick={() =>
                        updateFormData(
                          "saveButton",
                          completed,
                          values,
                          "Clinical_Details_Completed"
                        )
                      }
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      {isButtonLoading ? (
                        <CircularProgress size="1.75rem" />
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </AccordionActions>
                </Accordion>
              </Form>
            </MuiPickersUtilsProvider>
          )}
        </Formik>
      </div>
    )
  );
};
export default ClinicalDetails;
