import { createSlice } from "@reduxjs/toolkit";
import { setSeconds } from "date-fns";

let LOGIN_ATTEMPTS = "loginAttempts";
const initialState = {
  isAuthenticating: false,
  error: null,
  loginAttempts: parseInt(localStorage.getItem(LOGIN_ATTEMPTS)) || 0,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.error = "";
      localStorage.clear();
      state.loginAttempts = 0;
    },

    setError(state, action) {
      state.error = action.payload;
    },

    loginFailure(state, action) {
      localStorage.setItem("error", action.payload);
      state.loginAttempts += 1;
      localStorage.setItem(LOGIN_ATTEMPTS, state.loginAttempts);

      if (state.loginAttempts % 3 === 0) {
        let d = new Date();

        let retryDate = new Date(
          d.getTime() +
            Math.pow(2, parseInt(localStorage.getItem(LOGIN_ATTEMPTS))) * 1000
        );
        localStorage.setItem("retryDate", retryDate);
      } else {
        state.error = action.payload;
      }
    },
    logoutSuccess(state, action) {
      state.error = null;
      localStorage.clear();
    },
    logoutFailure(state, action) {
      state.error = action.payload;
    },
    setIsAuthenticating(state, action) {
      state.isAuthenticating = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  setError,
  setIsAuthenticating,
} = auth.actions;

export const setErrorMessage = (message) => {
  return (dispatch) => {
    dispatch(setError(message));
  };
};

export const login = ({ email, password }) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch(setIsAuthenticating(true));
    const firebase = getFirebase();
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(
        () => dispatch(loginSuccess()),
        (err) => dispatch(loginFailure(err))
      )
      .finally(() => dispatch(setIsAuthenticating(false)));
  };
};

export const logout = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch(setIsAuthenticating(true));
    const firebase = getFirebase();

    return firebase
      .auth()
      .signOut()
      .then(
        () => dispatch(logoutSuccess()),
        (err) => dispatch(logoutFailure(err))
      )
      .finally(() => dispatch(setIsAuthenticating(false)));
  };
};

export default auth.reducer;
