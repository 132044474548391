import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventList: [],
  eventListLoading: false,
  error: null,
};

const tsa = createSlice({
  name: "tsa",
  initialState,
  reducers: {
    setTimeseriesEvents(state, action) {
      state.eventList = action.payload;
    },
    addTimeseriesEvent(state, action) {
      return {
        ...state,
        eventList: state.eventList.concat(action.payload),
      };
    },
    clearTimeseriesEvents(state, action) {
      return {
        ...state,
        eventList: [],
      };
    },
    setIsEventListLoading(state, action) {
      state.eventListLoading = action.payload;
    },
  },
});

export const {
  setTimeseriesEvents,
  addTimeseriesEvent,
  clearTimeseriesEvents,
  setIsEventListLoading,
} = tsa.actions;

export const setAllTimeseriesEvents = (id, result) => {
  return (dispatch) => {
    let tsEventArray = result.find((el) => el.id === parseInt(id))
      .timeseriesevents;
    dispatch(setTimeseriesEvents(tsEventArray));
  };
};

export const addTSEvent = (eventToSubmit) => {
  return addTimeseriesEvent(eventToSubmit);
};

export const clearEventList = () => {
  return clearTimeseriesEvents();
};

export default tsa.reducer;
