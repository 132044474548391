import React, { useState } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useStyles } from "./LoginPageStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { MIN_PASSWORD_LENGTH } from "../../utils/constants";
import { login } from "../../store/reducers/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import LoginError from "./components/LoginError/LoginError";

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isAuthenticating } = useSelector((state) => state.auth);
  const [loginDisabled, setLoginDisabled] = useState(false);

  return (
    <Container
      className={classes.root}
      fluid={1}
      component="main"
      maxWidth="xs"
    >
      <div className={classes.paper}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
            password: Yup.string()
              .min(
                MIN_PASSWORD_LENGTH,
                `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
              )
              .required("Password is required"),
          })}
          onSubmit={(fields) => dispatch(login(fields))}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    type="email"
                    component={TextField}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    variant="outlined"
                    disabled={false}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="password"
                    component={TextField}
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="current-password"
                    variant="outlined"
                    disabled={false}
                    size="small"
                  />
                </Grid>
                {!!localStorage.getItem("error") && (
                  <Grid item xs={12}>
                    <LoginError setLoginDisabled={setLoginDisabled} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isAuthenticating || loginDisabled}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default LoginPage;
