// react
import React, { useEffect, useState } from "react";
//redux
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setAllTimeseriesEvents,
  clearEventList,
} from "../../store/reducers/features/tsa/tsaSlice";
//mui
import { Container, Button } from "@material-ui/core";
//components
import Graph from "./components/Graph/Graph";
import { AddEventsForm } from "./components/AddEventsForm";
import { EventList } from "./components/EventList";
import { SelectAxisLabel } from "./components/SelectAxisLabel";
import { useStyles } from "./TimeSeriesAnnotationDetailPageStyles";
//3rd party
import { tsv } from "d3";
import { ADMIN_ROLE } from "../../roles";
import { usePermission } from "../../hooks/permissions/usePermission";
import PermissionDenied from "../PermissionDenied/PermissionDenied";

const roles = [ADMIN_ROLE];

const makeLine = (coords) => [{ data: coords }];

const TimeSeriesAnnotationDetailPage = ({ match, tokens }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = match.params;
  // const rawUrl = `http://127.0.0.1:8000/timeseriesfiles/${id}/raw`;
  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";
  const rawUrl = `${apiRoot}/timeseriesfiles/${id}/raw`;
  const timeseriesIdUrl = `${apiRoot}/timeseriesfiles/${id}/`;
  const timeseriesUrl = `${apiRoot}/timeseriesfiles/`;
  const [lineData, setLineData] = useState({ x: null, y: null });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [clicked, setClicked] = useState({ x: 0, y: 0 });
  const [addNewEvent, setAddNewEvent] = useState(false);
  const [allData, setAllData] = useState(null);
  const [axisLabel, setAxisLabel] = useState({
    x: "MR Time(s)",
    y: "PCO2 (mmHg)",
  });
  const { eventList } = useSelector((state) => state.tsa);
  let xPoints = [];
  let yPoints = [];
  let evl = [];
  const [axisLabels, setAxisLabels] = useState([]);
  const [isEventListLoaded, setIsEventListLoaded] = useState(false);

  useEffect(() => {
    formatData(rawUrl);
    getEventList(id);
    return () => {
      dispatch(clearEventList());
    };
  }, []);
  useEffect(() => {
    if (!allData) return;
    allData
      .filter((row) => row[axisLabel.y] !== "NaN")
      .map(
        (row) => (
          xPoints.push(parseFloat(row[axisLabel.x])),
          yPoints.push(parseFloat(row[axisLabel.y]))
        )
      );
    setLineData({ x: xPoints, y: yPoints });
  }, [axisLabel, allData]);

  const getEventList = (id) => {
    setIsEventListLoaded(false);
    fetch(timeseriesUrl, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${tokens.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllTimeseriesEvents(id, result));
        setIsEventListLoaded(true);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const formatData = (plotData) => {
    tsv(plotData, {
      credentials: "include",
      headers: { Authorization: `JWT ${tokens.accessToken}` },
    }).then((parsedData) => {
      parsedData.map(
        (row) => (
          xPoints.push(parseFloat(row[axisLabel.x])),
          yPoints.push(parseFloat(row[axisLabel.y]))
        )
      );
      // const coords = parsedData.map((row) => ({
      //   x: parseFloat(row[axisLabel.x]),
      //   y: parseFloat(row[axisLabel.y]),
      // }));
      setLineData({ x: xPoints, y: yPoints });
      setAllData(parsedData);
      setDataLoaded(true);
    });
  };
  let hasPermission = usePermission(roles);
  if (!hasPermission) return <PermissionDenied />;
  return (
    <Container className={classes.root} fluid={1}>
      <h1>Timeseries Annotation for {id}</h1>
      {dataLoaded ? (
        <>
          <SelectAxisLabel
            axisLabels={axisLabels}
            axisLabel={axisLabel}
            setAxisLabel={setAxisLabel}
            allData={allData}
            setLineData={setLineData}
            setAxisLabels={setAxisLabels}
          />
          <Graph
            lineData={lineData}
            clicked={clicked}
            setClicked={setClicked}
            xLabel={axisLabel.x}
            yLabel={axisLabel.y}
            eventList={eventList}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddNewEvent((prev) => !prev)}
          >
            {addNewEvent ? "Cancel" : "Add New Event"}
          </Button>

          {addNewEvent ? (
            <AddEventsForm
              clicked={clicked}
              setClicked={setClicked}
              tokens={tokens}
              timeseriesIdUrl={timeseriesIdUrl}
              axisLabel={axisLabel}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <div>Loading Graph...</div>
      )}
      {isEventListLoaded ? <EventList /> : <div>Loading Events List...</div>}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    tokens: state.firebase.auth.stsTokenManager,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(TimeSeriesAnnotationDetailPage);
