import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./AddSearchSubjectStyles";
import { SearchSubject } from "../SearchSubject";
import { AddSubject } from "../AddSubject";
import { STROKE_ROLE } from "../../../../../../roles";
import PermissionDenied from "../../../../../PermissionDenied/PermissionDenied";
import { usePermission } from "../../../../../../hooks/permissions/usePermission";

const roles = [STROKE_ROLE];

const componentToRender = {
  search: <SearchSubject />,
  add: <AddSubject />,
};

const AddSearchSubject = (props) => {
  const classes = useStyles();
  let hasPermission = usePermission(roles);
  if (!hasPermission) return <PermissionDenied />;
  const { enrollmenttype } = props.match.params;
  return <div>{componentToRender[enrollmenttype]}</div>;
};

AddSearchSubject.propTypes = {};

export default AddSearchSubject;
