import * as React from "react";
import { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CircularProgress } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import queryString from "query-string";
import { useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import Demographic from "./components/Demographic";
import StrokeComorbidities from "./components/StrokeComorbidities";
import ClinicalDetails from "./components/ClinicalDetails";
import MRI from "./components/MRI";
import Moca from "./components/Moca";
import ModifiedRankinScale from "./components/ModifiedRankinScale";
import Snap from "./components/Snap";
import NIHSS from "./components/NIHSS";
import BartelIndex from "./components/BartelIndex";
import { STROKE_ROLE } from "../../../../roles";
import { usePermission } from "../../../../hooks/permissions/usePermission";
import PermissionDenied from "../../../PermissionDenied/PermissionDenied";
import Handedness from "./components/Handedness";

const roles = [STROKE_ROLE];

const StrokeStudy = (props) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [strokeTreatment, setStrokeTreatment] = useState(null);
  const [visitNumber, setVisitNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://internal-performancephenomics-rest-api-b7z663sweq-uc.a.run.app"
      : "http://127.0.0.1:8000";

  const { accessToken } = useSelector(
    (state) => state.firebase.auth.stsTokenManager
  );

  useEffect(() => {
    getStrokeFormData().then((formValues) => getTreatmentData(formValues));
  }, []);

  const completedStatus = (completed) => {
    {
      return completed ? (
        <span style={{ color: "green" }}>completed</span>
      ) : (
        <span style={{ color: "#CD6F00" }}>incomplete</span>
      );
    }
  };

  const getStrokeFormData = () => {
    setIsLoading(true);
    let strokeid = queryString.parse(props.location.search).strokeid;
    setVisitNumber(queryString.parse(props.location.search).visitnumber);
    const STROKE_STUDY_ENDPOINT = `${apiRoot}/stroke_study/${strokeid}/`;

    return fetch(STROKE_STUDY_ENDPOINT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    }).then((response) => response.json());
  };
  const getTreatmentData = (formValues) => {
    const STROKE_TREATMENT_ENDPOINT = `${apiRoot}/stroke_received_treatment/`;

    fetch(STROKE_TREATMENT_ENDPOINT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setFormValues(formValues);
        setStrokeTreatment(result);
        console.log(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateFormData = (clickedButton, completed, values, completedField) => {
    setIsButtonLoading(true);
    let strokeid = queryString.parse(props.location.search).strokeid;
    const STROKE_STUDY_ENDPOINT = `${apiRoot}/stroke_study/${strokeid}/`;
    if (!!!values.Height) {
      values.Height = 0;
    }
    if (!!!values.Weight) {
      values.Weight = 0;
    }
    if (!!!values.Current_smoker_tobacco_num_of_years) {
      values.Current_smoker_tobacco_num_of_years = 0;
    }
    if (!!!values.Current_smoker_tobacco_cig_per_day) {
      values.Current_smoker_tobacco_cig_per_day = 0;
    }
    if (!!!values.Former_smoker_tobacco_num_of_years) {
      values.Former_smoker_tobacco_num_of_years = 0;
    }
    if (!!!values.Former_smoker_tobacco_cig_per_day) {
      values.Former_smoker_tobacco_cig_per_day = 0;
    }

    let updatedState = { ...formValues, ...values };

    let newState;
    if (clickedButton === "completeButton") {
      newState = Object.assign({}, updatedState);
      completed
        ? (newState[completedField] = false)
        : (newState[completedField] = true);
    }

    fetch(STROKE_STUDY_ENDPOINT, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify(
        clickedButton === "completeButton" ? newState : updatedState
      ),
    })
      .then((response) => response.json())
      .then((result) => {
        setFormValues(result);
        setIsButtonLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  let hasPermission = usePermission(roles);
  if (!hasPermission) return <PermissionDenied />;

  return !!formValues ? (
    <div style={{ maxWidth: "1080px", margin: "0 auto 150px auto" }}>
      {console.log(formValues)}
      <h1 style={{ textAlign: "center" }}>Stroke Study</h1>
      <div>
        {/* cant be too heavy */}
        <Demographic
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          completedStatus={completedStatus}
          formValues={formValues}
        />
        <Handedness
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <StrokeComorbidities
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <ClinicalDetails
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
          strokeTreatment={strokeTreatment}
        />
        <MRI
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <Moca
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <ModifiedRankinScale
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <Snap
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <NIHSS
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
        <BartelIndex
          updateFormData={updateFormData}
          isButtonLoading={isButtonLoading}
          formValues={formValues}
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

StrokeStudy.propTypes = {};

export default StrokeStudy;
