import React from "react";
import { Tooltip, useTheme } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const StatusWithToolTip = ({ completed }) => {
  const theme = useTheme();
  return (
    <>
      <Tooltip title={completed ? "Complete" : "Incomplete"} placement="top">
        <FiberManualRecordIcon
          fontSize="small"
          style={{
            color: completed
              ? theme.palette.success.main
              : theme.palette.warning.light,
            marginRight: theme.spacing(1),
          }}
        />
      </Tooltip>
    </>
  );
};
export default StatusWithToolTip;
