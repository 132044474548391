import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  subjectIDTitle: { marginTop: "50px" },
  subjectIDValue: { marginTop: "50px" },
  sessionTitle: { marginTop: "50px" },
  sessionValueContainer: { marginTop: "50px" },
  sessionDropdown: { minWidth: "120px" },
  confirmButton: { textAlign: "center", marginTop: "20px" },
}));
