import React from "react";
import { useStyles } from "./HomeCardStyles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";

const HomeCard = ({ title, description, imgPath, imgTitleAlt, linkProps }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <ButtonBase {...linkProps}>
        <CardActionArea>
          <CardMedia
            component="img"
            className={classes.media}
            alt={imgTitleAlt}
            image={imgPath}
            title={imgTitleAlt}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </ButtonBase>
    </Card>
  );
};
export default HomeCard;
